/**
 * Created by BETALOS on 27/01/2017.
 */
(function () {

    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        onComplete: complete,
        bindToController: true,
        controller: 'AgendaCtrl',
        locals: {isDialog: true},
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("frontdesk/views/agenda-dialog.tpl.html"),
    };

    function complete(scope, element) {
        $('#calendar', element).fullCalendar('rerenderEvents');
    }

})();