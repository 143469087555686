(function () {

    'use strict';

    class AetDialogCtrl {
        constructor($mdDialog, dcmService) {
            this.dialog = $mdDialog;
            this.service = dcmService;
            this.aet = this.aet ? this.aet : {};
        }

        static get $inject() {
            return ["$mdDialog", "dcmService"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.service.handleAet(this.aet)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AetDialogCtrl,
        template: require("parameters/views/dcm-aet-dialog.tpl.html"),
    };

})();