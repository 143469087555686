/**
 * Created by BETALOS on 17/05/2017.
 */
(function () {

    'use strict';

    const BAG = "item-bag";
    const DENTAL_PROCEDURE_DIALOG = require('dental-consultation/dialogs/dental-procedure-dialog');
    const DENTAL_PROCEDURE_GROUP_DIALOG = require('dental-consultation/dialogs/dental-procedure-group-dialog');

    class DentalSetupCtrl {
        constructor(dentalService, $q, $mdDialog, $translate, dragulaService, $scope, configService, $mdToast, $timeout, dictionaryService) {
            this.q = $q;
            this.scope = $scope;
            this.toast = $mdToast;
            this.timeout = $timeout;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.dentalService = dentalService;
            this.configService = configService;
            this.dragulaService = dragulaService;
            this.dictionaryService = dictionaryService;

            this.showGroups = true;
            this.groupFocus = true;
            this.endSortDelay = _.mnDelay(() => this.endSort(), 500);
            this.dictionaryGroup = dictionaryService.getDictionaryGroups();

            this.simpleToast = this.toast.simple()
                .hideDelay(1500)
                .position("bottom left")
                .textContent(this.translate['instant']('exam_setup_edit_success'))
        }

        static get $inject() {
            return ["dentalService", "$q", "$mdDialog", "$translate", "dragulaService", "$scope", "configService", "$mdToast", "$timeout", "dictionaryService"];
        }

        $onInit() {
            this.promise = this.q.all([
                this.dentalService.getDentalProcedureGroupsTable(),
                this.dentalService.getDentalProcedureTable(),
                this.configService.get(["dental_plan_step_dict", "dental_chart_opacity", "dental_procedure_group", "dental_perio_charting_reverse"], true)
            ]).then(data => {
                this.procedureGroups = data[0];
                this.procedures = data[1];

                this.dictField = _.get(data, '2.dental_plan_step_dict');
                this.chartOpacity = _.get(data, '2.dental_chart_opacity');
                this.groupsConfig = _.get(data, '2.dental_procedure_group');
                this.perioChartingReverse = _.get(data, '2.dental_perio_charting_reverse');
            });

            this.dragulaService.options(this.scope, BAG, {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let drake = this.dragulaService.find(this.scope, BAG).drake;

            drake.on("dragend", () => this.endSortDelay())
        }

        handleGroup(group, ev) {
            this.dialog.show(_.assign({}, DENTAL_PROCEDURE_GROUP_DIALOG, {
                targetEvent: ev,
                locals: {
                    item: _.get(group, 'id', null),
                }
            })).then(data => this.procedureGroups = _.pushOrUpdate(this.procedureGroups, data));
        }

        handleProcedure(procedure, ev) {
            this.dialog.show(_.assign({}, DENTAL_PROCEDURE_DIALOG, {
                targetEvent: ev,
                locals: {
                    item: _.get(procedure, 'id', null),
                }
            })).then(data => this.procedures = _.pushOrUpdate(this.procedures, data));
        }

        removeGroup(group, ev) {
            let confirm = this.dialog.confirm()
                .title(this.translate['instant']('dental_setup_group_confirm', {title: group.name}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate['instant']('confirm_ok'))
                .cancel(this.translate['instant']('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.dentalService.removeProcedureGroup(group)
                        .then(() => _.pull(this.procedureGroups, group))
                });
        }

        removeProcedure(procedure, ev) {
            let confirm = this.dialog.confirm()
                .title(this.translate['instant']('dental_setup_procedure_confirm', {title: procedure.name}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate['instant']('confirm_ok'))
                .cancel(this.translate['instant']('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.dentalService.removeDentalProcedure(procedure)
                        .then(() => _.pull(this.procedures, procedure));
                });
        }

        endSort() {
            this.configService.set({"dental_procedure_group": _.assign(this.groupsConfig, {order: this.getSortConfig()})}, true)
                .then(() => this.success());
        }

        handleConfigField() {
            this.configService.set({"dental_plan_step_dict": this.dictField}, true)
                .then(() => this.success());
        }

        handleOpacity() {
            this.configService.set({"dental_chart_opacity": this.chartOpacity}, true)
                .then(() => this.success());
        }

        handlePerioCharting() {
            this.configService.set({"dental_perio_charting_reverse": this.perioChartingReverse})
                .then(() => this.success());
        }

        success() {
            this.timeout(() => this.toast.show(this.simpleToast), 100);
        }

        getSortConfig() {
            return _.reduce(this.procedureGroups, (config, item, key) => {
                return _.set(config, item.id.toString(), key);
            }, {})
        }

    }

    module.exports = DentalSetupCtrl;

})();