/**
 * Created by Hp on 25/04/2017.
 */
(function () {

    'use strict';

    const {from, of} = require("rxjs");
    const {tap} = require('rxjs/operators');

    PlanningDialogCtrl.$inject = ["$mdDialog", "patientService", "$q", "system", "frontDeskService", "$state", "$translate"];

    function PlanningDialogCtrl($mdDialog, patientService, $q, system, frontDeskService, $state, $translate) {
        let vm = this;

        const dateFormat = system['date_format'].js;
        const timeFormat = system['time_format'].js;
        const agendaParams = require('parameters/json/agenda-params.json');

        let currentStateName = $state.$current['name'];
        let isVisit = _.startsWith(currentStateName, "app.visit");

        vm.field = {key: _.uniqueId('planing-dialog-comment'), label: $translate.instant('comment')};

        vm.$onInit = init;

        vm.cancel = cancel;
        vm.preSave = preSave;
        vm.handlePlanning = handlePlanning;
        vm.changeInterval = changeInterval;

        vm.patientCallback = patientCallback;
        vm.removePatient = removePatient;

        vm.query = query;

        function init() {
            vm.weekDays = agendaParams['week-days'];
            vm.inDetail = _.startsWith(currentStateName, 'app.visit.planning') || _.startsWith(currentStateName, 'app.planning');

            let promises = [];

            if (!_.isNil(vm.patient_id)) promises.push(patientService.getMinimalPatient(vm.patient_id));
            else promises.push({});

            if (!_.isNil(vm.currentPlanningId)) promises.push(frontDeskService.getPlanning(vm.currentPlanningId))
            else promises.push({
                seance_nbr: vm.sessions_nbr || 1,
                date: moment().format(dateFormat),
                start_time: moment().format(timeFormat),
                comment: vm.comment || "",
                frequency: {frequency_type: 'WEEKLY', repeat_frequency: 1}
            });

            vm.promise = $q.all(promises)
                .then(success);
        }


        function cancel() {
            $mdDialog.cancel();
        }

        function handlePlanning(toDetail, event) {
            vm.promise = frontDeskService.handlePlanning(vm.planning, event)
                .then(doneCallbacks);

            function doneCallbacks(data) {
                $mdDialog.hide(data);

                if (toDetail) {
                    let params = _.assign({planning: data.id}, isVisit ? {} : {patient: _.get(data, 'patient.id')});
                    $state.go(isVisit ? 'app.visit.planning.details' : 'app.planning.details', params);
                }
            }
        }

        function preSave(event) {
            if (vm.planning.id) return of({}).toPromise();
            else return from(frontDeskService.handlePlanning(vm.planning, event))
                .pipe(
                    tap(data => success(data))
                ).toPromise();

            function success(data) {
                vm.planning.id = data.id;
            }
        }


        function success(data) {
            vm.planning = _.chain(vm.planning)
                .assign({patient: data[0]}, data[1], vm.copy ? {date: moment().format(dateFormat)} : {})
                .omit(vm.copy ? 'id' : null)
                .value();

            if (_.isNil(vm.planning['end_time'])) changeInterval();
        }

        function changeInterval(reset = false) {
            if (reset || _.isNil(vm.planning.end_time)) {
                let duration = _.get(vm.planning, 'reason.appointment_duration', 20);
                vm.planning.end_time = moment(vm.planning.start_time, timeFormat).add(duration, 'minutes').format(timeFormat);
            }
        }


        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id)
                .then(doneCallback);

            function doneCallback(data) {
                vm.planning = _.assign({}, vm.planning, {patient: data});
            }

        }

        function query() {
            const nEqual = {"$ne": true};

            return {
                is_draft: nEqual,
                is_deleted: nEqual,
                is_archived: nEqual,
            };
        }

        function removePatient() {
            vm.planning.patient = {};
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: PlanningDialogCtrl,
        template: require("frontdesk/views/planning-dialog.tpl.html"),
    };

})();

