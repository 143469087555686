/**
 * Created by BETALOS on 20/01/2017.
 */
(function () {

    'use strict';

    const AGENDA_PARAMS = require('parameters/json/agenda-params.json');
    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');


    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: BackgroundEventDialogCtrl,
        template: require("frontdesk/views/backgroun-event-dialog.tpl.html"),
    };

    BackgroundEventDialogCtrl.$inject = [
        "$mdDialog", "frontDeskService", "system", "mnWebSocket", "moment", "$translate"
    ];

    function BackgroundEventDialogCtrl($mdDialog, frontDeskService, system, mnWebSocket, moment, $translate) {
        let vm = this;

        let timeFormat = system['time_format'].js;
        let dateFormat = system['date_format'].js;
        let dateTimeFormat = system['date_format'].naive;

        vm.$onInit = init;

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        vm.handleStartDateChange = handleStartDateChange;
        vm.handleEndDateChange = handleEndDateChange;

        vm.checkDuration = checkDuration;


        function init() {
            vm.isPractice = true;
            vm.weekDays = AGENDA_PARAMS['week-days'];
            vm.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});

            if (_.isEmpty(vm.event)) vm.event = {
                unavailable: true,
                is_all_day: true,
                is_all_week: true,
            }

            else vm.promise = frontDeskService.getBackgroundEvent(vm.event['id'])
                .then(success);

            function success(data) {
                data._dow = _.invokeMap(data._dow, 'toString');
                vm.event = data;
            }
        }

        function submit() {
            let event = _.assign(vm.event, vm.event.is_permanent ? handle_permanent() : handle_non_permanent());

            if (!checkDuration()) {
                event.is_all_week = false;
                event._dow = [];
            }

            vm.promise = frontDeskService.handleEvent(event)
                .then(success);

            function success(data) {
                mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
                $mdDialog.hide(data);
            }
        }

        function handle_non_permanent() {
            let startDate = moment(vm.event['start_date'], dateFormat);
            let endDate = moment(vm.event['end_date'], dateFormat);
            let startTime = moment(vm.event['start_time'], timeFormat);
            let endTime = moment(vm.event['end_time'], timeFormat);

            if (vm.event.is_all_day || !startTime.isValid() || !endTime.isValid()) {
                startTime = moment().set({hour: 0, minute: 0});
                endTime = moment().set({hour: 23, minute: 59});
            }

            let start = startDate
                .set({hours: startTime.get('hour'), minutes: startTime.get('minute')})
                .format(dateTimeFormat);

            let end = endDate
                .set({hours: endTime.get('hour'), minutes: endTime.get('minute')})
                .format(dateTimeFormat);

            return {
                start: start,
                end: end
            }
        }

        function handle_permanent() {
            return {
                start: moment(vm.event.is_all_day ? "00:00" : vm.event['start_time'], timeFormat).format(dateTimeFormat),
                end: moment(vm.event.is_all_day ? "23:59" : vm.event['end_time'], timeFormat).format(dateTimeFormat)
            }
        }


        function handleStartDateChange() {
            if (_.isNil(vm.event.end_date)) vm.event.end_date = vm.event.start_date;
            updateDateConstraint();
        }

        function handleEndDateChange() {
            if (_.isNil(vm.event.start_date)) vm.event.start_date = vm.event.end_date;
            updateDateConstraint();
        }

        function updateDateConstraint() {
            vm.minDate = moment(vm.event['start_date'], dateFormat).toDate();
            vm.maxDate = moment(vm.event['end_date'], dateFormat).toDate();
        }

        function checkDuration() {
            if(vm.event.is_permanent) return true;
            if (_.isNil(vm.event.start_date) && _.isNil(vm.event.end_date)) return false;

            let startDate = moment(vm.event['start_date'], dateFormat);
            let endDate = moment(vm.event['end_date'], dateFormat);
            let diff = endDate.diff(startDate, 'days')

            return diff > 0;
        }

    }

})();
