/**
 * Created by BETALOS on 27/01/2017.
 */
(function () {

    'use strict';

    class AlertFormDialogCtrl {
        constructor($mdDialog, notificationService, $translate, configService, authService) {
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.authService = authService;
            this.service = notificationService;
            this.configService = configService;

            this.patient = this.patient ? this.patient : null;
            this.trigger = this.trigger ?  this.trigger : "WR";
            this.triggers = [
                {
                    "key": "WR",
                    "title": "alert_trigger_wr"
                },
                {
                    "key": "SV",
                    "title": "alert_trigger_sv"
                },
                {
                    "key": "EV",
                    "title": "alert_level_ev"
                }
            ]
        }

        static get $inject() {
            return ["$mdDialog", "notificationService", "$translate", "configService", "authService"];
        }

        $onInit() {
            this.alert = this.alert ? _.assign(this.alert, {patient: this.patient}) : {
                level: "MINIMUM",
                is_permanent: false,
                patient: this.patient,
                trigger: this.trigger,
                trigger_for: this.authService.staff ? [this.authService.staff] : []
            }

            this.promise = this.configService.get("patient_alert_field", true)
                .then(value => {
                    this.field = {
                        label: this.$translate.instant('alert_description'),
                        meta_data: {
                            dict_uid: value
                        }
                    }
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit(trigger) {
            this.promise = this.service.handleAlert(this.alert, trigger)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        focusOnOpen: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AlertFormDialogCtrl,
        template: require("../views/alert-form-dialog.tpl.html"),
    };

})();