(function () {

    'use strict';

    module.exports = DcmConfigCtrl;

    DcmConfigCtrl.$inject = ["$mdDialog", "dcmService", "$q", "$mdToast", "$translate"];

    function DcmConfigCtrl($mdDialog, dcmService, $q, $mdToast, $translate) {
        let vm = this;

        let aetDialog = require('../dialogs/aet-dialog');
        let viewerDialog = require('../dialogs/dcm-viewer-dialog');

        let simpleToast = $mdToast.simple()
            .textContent($translate['instant']('dcm_modality_update_success'))
            .position("bottom left")
            .hideDelay(1500);

        let errorToast = $mdToast.simple()
            .position("bottom left")
            .hideDelay(5000);

        let delayedCallback = _.mnDelay(serviceCallbackDone, 300);

        vm.$onInit = init;

        vm.handleAet = handleAet;
        vm.removeAet = removeAet;
        vm.updateModality = updateModality;

        vm.handleViewer = handleViewer;
        vm.removeViewer = removeViewer;

        vm.startService = startService;
        vm.stopService = stopService;
        vm.restartService = restartService;

        function init() {
            vm.promise = $q.all([
                dcmService.getModalities(),
                dcmService.getAets(),
                dcmService.getViewers(),
                dcmService.getDmcServiceConfigs()
            ]).then(success);

            function success(data) {
                vm.modalities = data[0];
                vm.aets = data[1];
                vm.viewers = data[2];

                // dcm services
                handleServiceStatus(data[3]);
            }
        }

        function handleAet(aet, ev) {
            $mdDialog.show(_.assign(aetDialog, {
                targetEvent: ev,
                locals: {
                    aet: _.cloneDeep(aet)
                }
            })).then(success);

            function success(data) {
                vm.aets = _.pushOrUpdate(vm.aets, data);
            }
        }

        function removeAet(aet, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('dcm_aet_remove_confirm', {title: aet.title}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = dcmService.removeAet(aet)
                    .then(done)
            }

            function done() {
                _.pull(vm.aets, aet);
            }
        }

        function updateModality(modality) {
            dcmService.updateModality(modality)
                .then(_.mnDelay(updateModalitySuccess, 400));
        }

        function updateModalitySuccess() {
            $mdToast.show(simpleToast);
        }

        // dcm viewer
        function handleViewer(viewer, ev) {
            $mdDialog.show(_.assign(viewerDialog, {
                targetEvent: ev,
                locals: {
                    viewer: _.cloneDeep(viewer)
                }
            })).then(success);

            function success(data) {
                vm.viewers = _.pushOrUpdate(vm.viewers, data);
            }
        }

        function removeViewer(viewer, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('dcm_viewer_remove_confirm', {title: viewer.title}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = dcmService.removeViewer(viewer)
                    .then(done)
            }

            function done() {
                _.pull(vm.viewers, viewer);
            }
        }

        // dcm service impl
        function handleServiceStatus(data) {
            vm.mwl = _.find(data, {type: 'mwl'}) ? _.find(data, {type: 'mwl'}) : {type: 'mwl'};
            vm.qr = _.find(data, {type: 'qr'}) ? _.find(data, {type: 'qr'}) : {type: 'qr'};
            vm.store = _.find(data, {type: 'store'}) ? _.find(data, {type: 'store'}) : {type: 'store'};
        }

        function startService(name) {
            vm.servicePromise = dcmService.handleDmcServiceConfig(vm[name])
                .then(success);

            function success() {
                vm.servicePromise = dcmService.startDmcService(vm[name])
                    .then(delayedCallback, serviceCallbackError);
            }
        }

        function stopService(name) {
            vm.servicePromise = dcmService.stopDmcService(vm[name])
                .then(delayedCallback, serviceCallbackError);
        }

        function restartService(name) {
            vm.servicePromise = dcmService.restartDmcService(vm[name])
                .then(delayedCallback, serviceCallbackError);
        }

        function serviceCallbackDone() {
            vm.servicePromise = dcmService.getDmcServiceConfigs()
                .then(handleServiceStatus);
        }

        function serviceCallbackError(err) {
            let msg = errorToast.textContent(err);
            $mdToast.show(msg);
        }
    }

})();