/**
 * Created by amine on 13/08/2018.
 */
(function () {
    'use strict';

    const servers = [{
        type: "gmail"
    }, {
        type: "outlook"
    }, {
        type: "icloud",
        disabled: true
    }, {
        type: "other",
        requireConfig: true
    }];

    class EmailSMSSetupCtrl {
        constructor(externalConnectionService, $q, $mdToast, $translate) {
            this.consoles = false;
            this.servers = _.cloneDeep(servers);
            this.$q = $q;
            this.externalConnectionService = externalConnectionService;

            const simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            this.toast = () => $mdToast.show(simpleToast);
        }

        static get $inject() {
            return ["externalConnectionService", "$q", "$mdToast", "$translate"];
        }

        $onInit() {
            this.$q.all([
                this.externalConnectionService.get('smtp_server'),
                this.externalConnectionService.get('sms_server')
            ]).then(data => {
                this.smsConsole = this.emailConsole = {content: ""};
                this.smtpConfig = data[0];
                this.smsConfig = data[1] || {oadc: "INFO", is_active: false};
            }, _.noop);
        }

        submitSmtpSetup() {
            const value = _.assign({}, this.smtpConfig, {
                server: _.pick(this.smtpConfig.server, "type")
            });

            this.externalConnectionService.set({smtp_server: value})
                .then(this.toast, _.noop);
        }

        submitSmsSetup() {
            const value = _.assign({}, this.smsConfig);

            this.externalConnectionService.set({sms_server: value})
                .then(this.toast, _.noop);
        }

        sendConsoleMessage() {
            this.externalConnectionService.sendConsoleMessage(this.smsConsole)
                .then(data => {
                    this.smsConsole.content += `to:${this.smsConsole.tel}\nmessage:\n${this.smsConsole.msg}\n${JSON.stringify(data)}\n\n`
                });
        }
    }

    module.exports = EmailSMSSetupCtrl;

})();
