/**
 * Created by amine on 11/10/2018. ?
 */
(function () {
    'use strict';

    class EmailSMSUserSetupCtrl {
        constructor(externalConnectionService, $q, $mdToast, $translate) {
            this.$q = $q;
            this.externalConnectionService = externalConnectionService;

            this.lock = true;

            this.smsItems = [];
            this.smsModels = [];
            this.smsModel = {};

            this.emailItems = [];
            this.emailModels = [];
            this.emailModel = {};

            this.consentItems = [];
            this.consentModels = [];
            this.consentModel = {};

            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };

            this.options = [30, 20, 15, 10, 5];

            this.selectedTab = 0;
            this.promise = null;
            this.history = [];
            this.historyTotal = 0;
            this.historyQuery = {
                limit: 20,
                page: 1,
                order: "-created_at",
                search_all: "",
                start_date: null,
                end_date: null
            };

            this.reminderEditorOpts = {
                "context": "reminder-email",
                "auto_compile": false,
                "no_config_key": true,
                "patient": false
            };

            this.consentEditorOpts = {
                "context": "consent",
                "auto_compile": false,
                "no_config_key": true,
                "patient": false
            };

            this.emailEditorOpts = {
                "auto_compile": false,
                "no_config_key": true,
                "patient": false,
                "has_misc_menu": true,
                "has_patient_menu": true,
                "has_physician_menu": true,
                "has_measurement_menu": true,
            };

            this.onHistoryReorder = order => this.historyReorder(order);
            this.onHistoryPaginate = (page, limit) => this.historyPaginate(page, limit);
        }

        static get $inject() {
            return ["externalConnectionService", "$q", "$mdToast", "$translate"];
        }

        $onInit() {
            this.promise = this.$q.all([this.refreshSmsModels(), this.refreshEmailModels(), this.refreshConsentModels(), this.getHistory()]);
        }

        refreshSmsModels() {
            return this.externalConnectionService.getSMSModels()
                .then(data => {
                    this.smsModels = data;
                });
        }

        refreshEmailModels() {
            return this.externalConnectionService.getEmailModels()
                .then(data => {
                    this.emailModels = data;
                });
        }

        refreshConsentModels() {
            return this.externalConnectionService.getConsentModels()
                .then(data => {
                    this.consentModels = data;
                });
        }

        getHistory() {
            return this.externalConnectionService.getHistory(this.historyQuery)
                .then((data) => {
                    this.history = data.list;
                    this.historyTotal = data.length;
                })
        }

        cancel() {
            this.smsModel = {
                content: ""
            };
            this.emailModel = {
                content: "",
                is_reminder: false
            };
        }

        reminderChanged() {
            this.emailModel["content"] = "";
        }

        submitSMS() {
            this.externalConnectionService.saveSMSModel(this.smsModel)
                .then(() => this.promise = this.refreshSmsModels());
        }

        editSmsModel(item) {
            this.smsModel = _.cloneDeep(item);
        }

        deleteSmsModel(item) {
            if (this.smsModel.id == item.id) this.cancel();

            this.promise = this.externalConnectionService.deleteSMSModel(item)
                .then(() => _.remove(this.smsModels, {id: item.id}));
        }

        submitEmail() {
            this.externalConnectionService.saveEmailModel(this.emailModel)
                .then(() => this.promise = this.refreshEmailModels());
        }


        editEmailModel(item) {
            this.emailModel = _.cloneDeep(item);
        }

        deleteEmailModel(item) {
            if (this.emailModel.id == item.id) this.cancel();

            this.promise = this.externalConnectionService.deleteEmailModel(item)
                .then(() => _.remove(this.emailModels, {id: item.id}));
        }

        submitConsent() {
            this.externalConnectionService.saveConsentModel(this.consentModel)
                .then(() => this.promise = this.refreshConsentModels());
        }


        editConsentModel(item) {
            this.consentModel = _.cloneDeep(item);
        }

        deleteConsentModel(item) {
            if (this.consentModel.id == item.id) this.cancel();

            this.promise = this.externalConnectionService.deleteConsentModel(item)
                .then(() => _.remove(this.consentModels, {id: item.id}));
        }

        historyReorder(order) {
            this.historyQuery = _.assign(this.historyQuery, {order: order});
            this.promise = this.getHistory()
        }

        historyPaginate(page, limit) {
            this.historyQuery = _.assign(this.historyQuery, {page: page, limit: limit});
            this.promise = this.getHistory()
        }
    }

    module.exports = EmailSMSUserSetupCtrl;

})();
