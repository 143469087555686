(function () {
    'use strict';
    var mnOximetryChart = {
        controller: mnOximetryChartCtrl,
        controllerAs: "vm",
        bindings: {
            realTime: "=mnRealtime",
            chartObject: "=?chartObject",
            bluetoothServer: "=mnBluetoothServer",
            bluetoothDevice: "=mnBluetoothDevice",
            disconnectDevice: "<mnDisconnectDevice",
            exam: "=mnExam",

            formValid: "<mnValidForm",
            defaultParams: "=mnDefaultParams"
        },
        template: require('oximetry/views/oximetry-chart.tpl.html')
    };
    mnOximetryChartCtrl.$inject = ["$interval", "$mdDialog", "oximetryService", "system", "$scope"];

    function mnOximetryChartCtrl($interval, $mdDialog, oximetryService, system, $scope) {

        var vm = this;
        var chartParams = require('../json/oximetery-chart.json');
        var fullDateTimeFormat = system['full_datetime_format'].js;
        var oxiDevices = require("../json/oxi-services.json");

        var current_bearing_duration = 0;
        var bearing_idx = 0;
        var playBackMeasurements = [];

        vm.$onInit = init;
        vm.$onDestroy = destroy;
        vm.startExam = startExam;
        vm.stopExam = stopExam;
        vm.isReadOnly = isReadOnly;
        vm.reportBreak = reportBreak;
        vm.importExam = importExam;
        vm.endExam = endExam;
        vm.showButton = showButton;

        function init() {
            vm.oxiServices = oxiDevices[_.get(vm.defaultParams, 'nonin_model')];
            ;vm.chartObject = null;
            vm.stopped = false;
            vm.time_idx = 0;
            vm.timer = {value: "", sec: 0, min: 0, hr: 0};
            loadChartData();
            $scope.$watch("vm.exam.id", loadExam);
        }


        function destroy() {
            vm.disconnectDevice();
        }

        function loadExam() {
            if (!_.isNil(vm.exam.id) || vm.exam.is_imported) {
                vm.exam.recovery = _.map(vm.exam.recovery, function (e) {
                    e.fillColor = _.get(vm.defaultParams, 'chart_colors.recovery') || "#df2037";
                    return e;
                });
                vm.chartObject.dataProvider = _.map(_.cloneDeep(_.concat(vm.exam.measurements, vm.exam.recovery || [])), function (e) {
                    e.date = moment(e.date, fullDateTimeFormat);
                    return e;
                });
                vm.chartObject.guides = [];
                _.forEach(vm.exam.breaks, function (item) {
                    vm.chartObject.guides.push({
                        date: item.start_time,
                        toDate: item.end_time,
                        lineColor: _.get(vm.defaultParams, 'chart_colors.break') || "#b7e021",
                        lineAlpha: 1,
                        fillAlpha: 0.2,
                        fillColor: _.get(vm.defaultParams, 'chart_colors.break') || "#b7e021",
                        dashLength: 2,
                        inside: true,
                        labelRotation: 90,
                        label: item.reason || item.other_reason
                    });
                });
                vm.chartObject.validateData();
            }
            else {
                reInitChart();
            }
        }

        function reInitChart() {
            vm.chartObject.dataProvider = [];
            vm.chartObject.guides = [];
            vm.chartObject.validateData();
        }

        function startExam() {
            reInitChart();
            vm.exam.start_datetime = moment().format(fullDateTimeFormat);

            vm.bluetoothDevice.getPrimaryService(vm.oxiServices['noninOxiService']['code'])

                .then(service => service.getCharacteristic(vm.oxiServices['noninOxiService']['oximetry_measurement']))
                .then(characteristic => {
                    vm.measurementCaracteristic = characteristic;
                    enableMeasurementCharacteristics();
                })
                .catch(err => console.log('error:', err));
        }

        function enableMeasurementCharacteristics(resume = false) {
            vm.measurementCaracteristic.startNotifications()
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    resume ? timerResume() : timerStart();

                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleNotifications)
                })
        }

        function handleNotifications(event) {

            let data = handleData(event.target.value.buffer);

            if (vm.chartObject.dataProvider.length > 19) vm.chartObject.dataProvider.shift();

            vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));
            vm.chartObject.validateData();
        }


        function handleData(value) {
            let a = new Uint8Array(value);
            vm.time_idx++;

            var meas_date_time = new Date(new Date().setHours(0, 0, vm.time_idx));

            let data = {
                date: moment(meas_date_time, fullDateTimeFormat).format(fullDateTimeFormat),
                spo: a[7],
                hr: a[9],
                idx: a[6],
                fillColor: "#fff"
            };
            if (vm.exam['type'] === "six-min" && meas_date_time.getTime() > new Date(new Date().setHours(0, 6, 1)).getTime()) {
                vm.stopExam();

            }
            // stop here réentrainement à l'effort & wats
            if (vm.exam.type === 're-training-effort') {
                if (bearing_idx < vm.exam.protocol.bearing.length && meas_date_time.getTime() > new Date(new Date().setHours(0, current_bearing_duration + vm.exam.protocol.bearing[bearing_idx].duration, 1)).getTime()) {
                    current_bearing_duration += vm.exam.protocol.bearing[bearing_idx].duration;
                    bearing_idx < vm.exam.protocol.bearing.length - 1 ? bearing_idx += 1 : _.noop();
                }
                data.watts = vm.exam.protocol.bearing[bearing_idx].watts;
            }
            if (vm.exam.stop_datetime) {
                data.fillColor = _.get(vm.defaultParams, 'chart_colors.recovery') || "#e45a62";
                vm.exam.recovery.push(data);
            }
            else {
                vm.exam.measurements.push(data);
            }
            return data;
        }


        var exam_end_time = 0;

        function is_MaxDuration() {

            if (vm.exam.stop_datetime) {

                return new Date(new Date().setHours(0, 0, vm.time_idx)).getTime() > exam_end_time;
            }
            else {
                if (vm.exam.type === 'six-min') {
                    return new Date(new Date().setHours(0, 0, vm.time_idx)).getTime() > new Date(new Date().setHours(0, 6, _.get(vm.defaultParams, 'recovery_duration') || 20)).getTime();
                }
                else {
                    return false;
                }
            }

        }


        function stopExam() {
            vm.exam.stop_datetime = moment().format(fullDateTimeFormat);
            exam_end_time = new Date(new Date().setHours(vm.timer.hr, vm.timer.min, vm.timer.sec + (_.get(vm.defaultParams, 'recovery_duration') || 20))).getTime();
        }

        function endExam() {

            timerStop();

            if (vm.defaultParams['auto_save']) {
                if (vm.formValid) oximetryService.saveExam(vm.exam).then(function success(data) {
                    vm.exam = data;
                });
            }

            if (vm.measurementCaracteristic) {
                try {
                    vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged',
                        handleNotifications);
                    vm.exam.end_datetime = moment().format(fullDateTimeFormat);
                    vm.measurementCaracteristic.stopNotifications();

                } catch (error) {
                    console.log('Argh! ' + error);
                }
            }

            vm.chartObject.dataProvider = _.map(_.cloneDeep(_.concat(vm.exam.measurements, vm.exam.recovery || [])), function (e) {
                e.date = moment(e.date, fullDateTimeFormat);
                return e;
            });
            vm.chartObject.validateData();
        }

        function loadChartData() {
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'spo1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.spo');
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'hr1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.hr');
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'watts1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.watts');

            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'spo'})].lineColor = _.get(vm.defaultParams, 'chart_colors.spo');
            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'hr'})].lineColor = _.get(vm.defaultParams, 'chart_colors.hr');
            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'watts'})].lineColor = _.get(vm.defaultParams, 'chart_colors.watts');

            vm.chartObject = AmCharts.makeChart("chart-div", _.assign(_.cloneDeep(chartParams), {"dataProvider": []}));
            vm.chartObject.addListener("dataUpdated", zoomChart);
            zoomChart();


        }

        function zoomChart() {
            vm.chartObject.zoomToIndexes(vm.chartObject.dataProvider.length - 20, vm.chartObject.dataProvider.length - 1);
        }

        function isReadOnly(is_starting = false) {

            return is_starting ? !_.isNil(vm.exam.start_datetime) : !vm.exam.start_datetime || !_.isNil(vm.exam.id);
        }

        function showButton(type) {
            switch (type) {
                case "start":
                    return vm.exam.type != 'nocturne' && _.isNil(vm.exam.start_datetime);
                case "end":
                    return vm.exam.type != 'nocturne' && !_.isNil(vm.exam.start_datetime) && !_.isNil(vm.exam.stop_datetime) && _.isNil(vm.exam.end_datetime);
                case "stop":
                    return vm.exam.type != 'nocturne' && !_.isNil(vm.exam.start_datetime) && _.isNil(vm.exam.stop_datetime);

                default:
                    return false;
            }
        }

        function reportBreak() {
            var break_time_idx = vm.time_idx;
            var break_moment = new Date(new Date().setHours(0, 0, break_time_idx));

            $mdDialog.show(_.assign(require('oximetry/dialogs/break-dialog'), {
                locals: {
                    breakReasons: _.get(vm.defaultParams, 'break_reasons'),
                    break: {start_time: _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat))}
                }
            })).then(reportCallBack);

            function reportCallBack(data) {
                if (data.type === 'short') {
                    break_moment.setHours(0, 0, break_time_idx + _.get(vm.defaultParams, 'short_break_duration') || 5);
                    data.end_time = _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat));
                }


                if (data.type === 'long') {
                    break_moment.setHours(0, 0, vm.time_idx + _.get(vm.defaultParams, 'short_break_duration') || 5);
                    data.end_time = _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat));
                }
                vm.exam.breaks.push(data);
            }
        }

        function importExam() {
            reInitChart();
            vm.exam.start_datetime = moment().format(fullDateTimeFormat);
            vm.bluetoothDevice.getPrimaryService(vm.oxiServices['pulseOxiService']['code'])
                .then(service => service.getCharacteristic(vm.oxiServices['pulseOxiService']['spot_check_measurement']))
                .then(c => {
                    vm.spotCheckCaracteristic = c;
                    return vm.spotCheckCaracteristic.startNotifications();
                })
                .then(c_post => {
                    vm.spotCheckCaracteristic = c_post;
                    vm.spotCheckCaracteristic.addEventListener('characteristicvaluechanged', _.get(vm.defaultParams, 'nonin_model') == "3150" ? handleMemoryPlayBackNotification : handleSpotCheckNotification);
                    enableRecordAccess();
                })

                .catch(err => console.log('error:', err));
        }

        function enableRecordAccess() {
            vm.bluetoothDevice.getPrimaryService(vm.oxiServices['pulseOxiService']['code'])
                .then(service => service.getCharacteristic(vm.oxiServices['pulseOxiService']['record_access_control']))
                .then(c => {
                    vm.recordCaracteristic = c;
                    return vm.recordCaracteristic.startNotifications();
                })
                .then(c_post => {
                    vm.recordCaracteristic = c_post;

                    vm.recordCaracteristic.writeValue(Uint8Array.of(0x71, 0x4E, 0x4D, 0x49));


                    vm.recordCaracteristic.addEventListener('characteristicvaluechanged', handleRecordAccessNotification);
                    // vm.recordCaracteristic.writeValue(Uint8Array.of(4, 1));
                    // vm.recordCaracteristic.writeValue(Uint8Array.of(1, 1));
                })
                .catch(value => {
                    console.log('indication value.', value);
                })
        }

        function handleSpotCheckNotification(event) {
            var value = new Uint8Array(event.target.value.buffer);
            var meas_date_time = oximetryService.newDateFromArray(_.concat(oximetryService.toInt16(value.slice(5, 7)), value[7] - 1, Array.from(value.slice(8, 12))));

            let data = {
                date: moment(meas_date_time, fullDateTimeFormat).format(fullDateTimeFormat),
                spo: oximetryService.readSFLOAT(new DataView(new Uint8Array(value.slice(1, 3)).buffer), 0, true),
                hr: oximetryService.readSFLOAT(new DataView(new Uint8Array(value.slice(3, 5)).buffer), 0, true),
                fillColor: "#fff"
            };
            vm.exam.measurements.push(data);
            vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));

            vm.chartObject.validateData();
        }


        function handleMemoryPlayBackNotification(event) {
            var value = new Uint8Array(event.target.value.buffer);
            playBackMeasurements.push(Array.from(value));
        }


        function formatPlayBackDate(date_array) {
            let m = moment();

            m.set('years', 2000 + date_array[3]);
            m.set('months', date_array[0]);
            m.set('date', date_array[1]);
            m.set('hours', date_array[7]);
            m.set('minutes', date_array[4]);
            m.set('seconds', date_array[6]);


            return m;
        }

        function handleRecordAccessNotification(event) {
            let value = new Uint8Array(event.target.value.buffer);
            if (value[0] == 243) {
                let res = _.chain(playBackMeasurements)
                    .flatten()
                    .toString()
                    .split("254,253,251,")
                    .slice(1, -1)
                    .map((e) => {
                        let data = _.split(e, ",");
                        let meas_time = formatPlayBackDate(_.chain(data).slice(12, 21).map(_.parseInt).value())
                        let secondRate = data[0];
                        return _.chain(data).slice(30, data.length).map(_.parseInt).chunk(3)
                            .map((m, i) => {
                                return {
                                    hr: m[0],
                                    spo: m[1],
                                    date: meas_time.subtract("seconds", secondRate).format(fullDateTimeFormat),
                                    fillColor: "#fff"
                                }
                            })
                            .value()

                    })
                    .flatten()
                    .reject((e) => {
                        return _.includes([255, NaN], e.hr)
                    })
                    .reverse()
                    .forEach((data) => {

                        vm.exam.measurements.push(data);

                        vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));
                    })
                    .value();
                vm.chartObject.validateData();
                zoomChart();
            }

        }

        // **********timer************
        function timerResume() {
            vm.timerProcess = $interval(timerHandler, 1000);
        }

        function timerStart() {

            vm.timerProcess = $interval(timerHandler, 1000);
            timerReset();
        }

        function timerReset() {
            vm.timer.value = null;
            vm.timer.sec = 0;
            vm.timer.min = 0;
            vm.timer.hr = 0;
        }

        function timerStop() {

            if (vm.timerProcess) {
                $interval.cancel(vm.timerProcess);
            }
        }

        function timerHandler() {

            if (is_MaxDuration()) vm.endExam();
            if (++vm.timer.sec === 60) {
                vm.timer.sec = 0;
                if (++vm.timer.min === 60) {
                    vm.timer.min = 0;
                    if (++vm.timer.hr === 24) vm.timer.hr = 0;
                }
            }
            vm.timer.value = vm.timer.hr > 0 ? (vm.timer.hr < 10 ? "0" + vm.timer.hr : vm.timer.hr) + ":" : "";
            vm.timer.value += (vm.timer.min < 10 ? "0" + vm.timer.min : vm.timer.min) + ":" + (vm.timer.sec < 10 ? "0" + vm.timer.sec : vm.timer.sec);
        }

    }

    module.exports = mnOximetryChart;

})();
