(function () {

    'use strict';

    class CalendarDurationDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;

            this.filter = {};
            this.filterEvents = list => this.filter = _.assign(this.filter, list);
            this.handleDuration = (obj, event, jsEv) => {
                this.dialog.hide(obj);
            }
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        cancel() {
            this.dialog.cancel();
        }
    }


    module.exports = {
        multiple: true,
        controllerAs: "vm",
        onComplete: complete,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: CalendarDurationDialogCtrl,
        template: require("frontdesk/views/calendar-duration-dialog.tpl.html"),
    };

    function complete(scope, element) {
        $('#calendar', element).fullCalendar('rerenderEvents');
    }

})();