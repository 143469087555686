(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    const LINE_STATUS = {
        'rdvLate': {
            'color': '#F4511E',
            'icon': 'mdi-clock-alert',
        },
        'rdvMissed': {
            'color': '#D50000',
            'icon': 'mdi-call-missed',
        },
        'rdvWaiting': {
            'color': '#00E676',
            'icon': 'mdi-calendar-clock',
        },
        'entryWR': {
            'color': '#0097A7',
            'icon': 'mdi-seat-legroom-extra',
        },
        'entryMissed': {
            'color': '#D50000',
            'icon': 'mdi-account-clock',
        },
        'entrySV': {
            'color': '#8E24AA',
            'icon': 'mdi-hospital',
        },
        'entryEV': {
            'color': '#3F51B5',
            'icon': 'mdi-clock-end',
        },
        'entryClosedAtMidnight': {
            'color': '#FFAB00',
            'icon': 'mdi-exit-to-app',
        },
        'entryEX': {
            'color': '#78909C',
            'icon': 'mdi-exit-run',
        },
    };

    class WorkFlowService {
        constructor($q, $http, mnWebSocket, moment, $translate, system, $sce) {
            this.q = $q;
            this.$sce = $sce;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.http = $http;
            this.translate = $translate;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.dateTimeFormat = system['date_format'].naive;

            this.filterSubject = new BehaviorSubject([
                {id: 'RDV', label: 'daily_workflow_filter_rdv'},
                {id: 'WR', label: 'daily_workflow_filter_wr'},
                {id: 'SV', label: 'daily_workflow_filter_sv'},
                {id: 'EV', label: 'daily_workflow_filter_ev'},
                {id: 'EX', label: 'daily_workflow_filter_ex'},
            ]);

            this.columnConfiguration = [
                {
                    key: "rdv_start_time",
                    label: "daily_workflow_rdv_start_time",
                    classes: "text-center",
                    getContent: item => {
                        return `<span>${item['start_time'] ? item['start_time'] : ''}</span>`;
                    }
                }, {
                    key: "patient_name",
                    label: "daily_workflow_patient_name",
                    classes: 'workflow-patient-name',
                    getContent: item => {
                        let html = `<span style=${item.patient_is_draft ? "color:#e53935;" : ""} class="flex">${item['patient_full_name']}</span>`;

                        if (!item['patient_has_visit'])
                            html += `<md-icon class="mdi mdi-checkbox-blank-circle md-icon-red-600"></md-icon>`;
                        if (item['is_urgent'])
                            html += `<md-icon class="mdi mdi-ambulance md-icon-red-600"></md-icon>`;

                        html += `<mn-patient-alert icon-button patient="${item.patient_id}" class="workflow-icon-button"></mn-patient-alert>`;

                        return html;
                    }
                }, {
                    key: "patient_age",
                    label: "daily_workflow_patient_age",
                    getContent: item => {
                        return `<span>${item['patient_age'] ? item['patient_age'] : ''}</span>`;
                    }
                }, {
                    key: "patient_gender",
                    label: "daily_workflow_patient_gender",
                    classes: "text-center",
                    getContent: item => {
                        let icon = "";
                        switch (item.patient_gender) {
                            case 'MALE':
                                icon = 'mdi-human-male';
                                break;
                            case 'FEMALE':
                                icon = 'mdi-human-female';
                                break;
                        }

                        return `<md-icon aria-label="gender" class="mdi ${icon} ${item.patient_gender} workflow-gender-icon"></md-icon>`;
                    }
                }, {
                    key: "patient_insurance",
                    label: "daily_workflow_patient_insurance",
                    getContent: item => {
                        return `<span>${item['patient_default_insurance'] ? item['patient_default_insurance'] : ''}</span>`;
                    }
                }, {
                    key: "status",
                    label: "daily_workflow_status",
                    classes: "text-center",
                    getContent: item => {
                        return `
                            <md-icon class="mdi ${item['status']['icon']}" style="color: ${item['status']['color']}">
                            <md-tooltip md-direction="bottom" md-delay="150">
                                <span>${item['status']['label']}</span>
                            </md-tooltip>
                        `;
                    }
                }, {
                    key: "reason",
                    label: "daily_workflow_reason",
                    classes: "text-center",
                    getContent: item => {
                        return `<span style="background: ${item['reason']['color']}" class="mn-badge">${item['reason']['value']}</span>`;
                    }
                }, {
                    key: "agenda",
                    label: "daily_workflow_agenda",
                    classes: "text-center",
                    getContent: item => {
                        return _.isNil(item['agenda']) ? "" : `<span style="background: ${item['agenda']['color']}" class="mn-badge">${item['agenda']['name']}</span>`;
                    }
                }, {
                    key: "entry_time",
                    label: "daily_workflow_entry_time",
                    getContent: item => {
                        return `<span>${_.isNil(item['entry_time']) ? '' : this.moment(item['entry_time'], this.dateTimeFormat).format('HH:mm')}</span>`
                    }
                }, {
                    key: "waiting_time",
                    label: "daily_workflow_waiting_time",
                    getContent: item => {
                        return `<span>${item['status']['waitingTime'] ? item['status']['waitingTime'] : ''}</span>`;
                    }
                }, {
                    key: "visit_time",
                    label: "daily_workflow_visit_time",
                    getContent: item => {
                        return `<span>${item['status']['visitTime'] ? item['status']['visitTime'] : ''}</span>`;
                    }
                }, {
                    key: "physician",
                    label: "daily_workflow_physician",
                    getContent: item => {
                        return `<span>${item['physician_full_name'] ? item['physician_full_name'] : ''}</span>`;
                    }
                }, {
                    key: "comment",
                    label: "daily_workflow_comment",
                    getContent: item => {
                        return `<span>${item['comment'] ? item['comment'] : ''}</span>`;
                    }
                }
            ];
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "moment", "$translate", "system", "$sce"];
        }

        getWorkFlow(date) {
            const deferred = this.q.defer();
            const query = {
                date: this.moment(date).format('YYYY-MM-DD')
            };

            this.ws.call('frontdesk.WorkFlow.get_daily_workflow', query).then(data => {
                const items = this.handleData(data);
                deferred.resolve(items);
            }, deferred.reject);

            return deferred.promise;
        }


        handleData(data) {
            return _.chain([])
                .concat(
                    this.handleEntriesWithAppointments(data),
                    this.handleAppointmentsWithoutEntries(data),
                    this.handleEntriesWithoutAppointments(data)
                )
                .orderBy([
                    item => item['is_urgent'] ? 1 : 0,
                    item => this.moment(item.start ? item.start : item.entry_time).valueOf()
                ], ["desc", "asc"])
                .value()
        }

        handleEntriesWithAppointments(data) {
            let entries = _.filter(data.entries, 'has_appointment');
            let appointments = _.filter(data.appointments, 'is_entered');

            return _.map(entries, item => {
                let appointment = _.find(appointments, ['id', item['appointment_id']]);
                return _.assign({}, appointment, item, {
                    track_id: `entry-${item.id}`,
                    comment: item.other_comment,
                    status: this.handleEntryStatus(item),
                });
            });
        }

        handleAppointmentsWithoutEntries(data) {
            return _.chain(data.appointments)
                .filter(['is_entered', false])
                .map(item => {
                    return _.assign({}, item, {
                        stat: 'RDV',
                        exit_time: null,
                        entry_time: null,
                        visit_end_time: null,
                        visit_start_time: null,
                        comment: item.other_comment,
                        track_id: `appointment-${item.id}`,
                        status: this.handleAppointmentStatus(item),
                    });
                })
                .value();
        }

        handleEntriesWithoutAppointments(data) {
            return _.chain(data.entries)
                .filter(['has_appointment', false])
                .map(item => _.assign({}, item, {track_id: `entry-${item.id}`, status: this.handleEntryStatus(item), comment: item.other_comment}))
                .value();
        }

        orderData(items) {
            return _.orderBy(items, [
                item => item['is_urgent'] ? 1 : 0,
                item => this.moment(item.start ? item.start : item.entry_time).valueOf()
            ], ["desc", "asc"]);
        }


        // items status
        handleAppointmentStatus(item) {
            const now = this.moment();
            const appointmentDate = this.moment(item.date, this.dateFormat);
            const appointmentDateTime = this.moment(item.start, this.dateTimeFormat);
            const duration = this.moment.duration(now.diff(appointmentDateTime)).humanize();

            if (appointmentDate.isSame(now, 'day')) {
                if (now.isSameOrAfter(appointmentDateTime, 'm')) return _.assign({}, LINE_STATUS['rdvLate'], {
                    label: this.translate.instant('daily_workflow_rdv_late', {duration}),
                });
                else return _.assign({}, LINE_STATUS['rdvWaiting'], {
                    label: this.translate.instant('daily_workflow_rdv_waiting', {duration})
                });
            } else if (appointmentDate.isBefore(now, 'day')) return _.assign({}, LINE_STATUS['rdvMissed'], {
                label: this.translate.instant('daily_workflow_rdv_missed')
            })

            else return _.assign({}, LINE_STATUS['rdvWaiting'], {
                    label: this.translate.instant('daily_workflow_rdv_waiting', {duration})
                })
        }

        handleEntryStatus(item) {
            const now = this.moment();
            const entryTime = this.moment(item['entry_time'], this.dateTimeFormat);
            const visitStartTime = this.moment(item['visit_start_time'], this.dateTimeFormat);
            const visitEndTime = this.moment(item['visit_end_time'], this.dateTimeFormat);
            const exitTime = this.moment(item['exit_time'], this.dateTimeFormat);

            const isPast = entryTime.isBefore(now, 'day');

            if (item.stat === 'WR') {
                if (isPast) {
                    return _.assign({}, LINE_STATUS['entryMissed'], {
                        label: this.translate.instant('daily_workflow_entry_missed')
                    })
                } else {
                    let duration = this.moment.duration(now.diff(entryTime)).humanize();

                    return _.assign({}, LINE_STATUS['entryWR'], {
                        waitingTime: _.capitalize(duration),
                        label: this.translate.instant('daily_workflow_entry_wr', {duration})
                    });
                }
            } else if (item.stat === 'SV') {
                let waitingTime = this.moment.duration(visitStartTime.diff(entryTime)).humanize();

                if (isPast) {
                    return _.assign({}, LINE_STATUS['entryClosedAtMidnight'], {
                        waitingTime: _.capitalize(waitingTime),
                        label: this.translate.instant('daily_workflow_entry_closed_at_midnight')
                    })
                } else {
                    let duration = this.moment.duration(now.diff(visitStartTime)).humanize();

                    return _.assign({}, LINE_STATUS['entrySV'], {
                        waitingTime: _.capitalize(waitingTime),
                        visitTime: _.capitalize(duration),
                        label: this.translate.instant('daily_workflow_entry_sv', {duration}),
                    })
                }
            } else if (item.stat === 'EV') {
                let waitingTime = this.moment.duration(visitStartTime.diff(entryTime)).humanize();
                let visitTime = this.moment.duration(visitEndTime.diff(visitStartTime)).humanize();

                if (isPast) {
                    return _.assign({}, LINE_STATUS['entryClosedAtMidnight'], {
                        waitingTime: _.capitalize(waitingTime),
                        visitTime: _.capitalize(visitTime),
                        label: this.translate.instant('daily_workflow_entry_closed_at_midnight')
                    });
                } else {
                    let duration = this.moment.duration(now.diff(visitEndTime)).humanize();
                    return _.assign({}, LINE_STATUS['entryEV'], {
                        waitingTime: _.capitalize(waitingTime),
                        visitTime: _.capitalize(visitTime),
                        label: this.translate.instant('daily_workflow_entry_ev', {duration}),
                    });
                }
            } else if (item.stat === 'EX') {
                let duration = this.moment.duration(now.diff(exitTime)).humanize();

                if (visitStartTime.isValid()) {
                    let waitingTime = this.moment.duration(visitStartTime.diff(entryTime)).humanize();
                    let visitTime = this.moment.duration(
                        visitEndTime.isValid() ? visitEndTime.diff(visitStartTime) : exitTime.diff(visitStartTime)
                    ).humanize();

                    let label = this.translate.instant(
                        `daily_workflow_entry_ex${isPast ? '_past' : ''}`,
                        {duration: isPast ? exitTime.format(this.timeFormat) : duration}
                    )

                    return _.assign({}, LINE_STATUS['entryEX'], {
                        label, waitingTime: _.capitalize(waitingTime), visitTime: _.capitalize(visitTime),
                    });
                } else {
                    let waitingTime = this.moment.duration(exitTime.diff(entryTime)).humanize();

                    return _.assign({}, LINE_STATUS['entryMissed'], {
                        waitingTime: _.capitalize(waitingTime),
                        label: this.translate.instant('daily_workflow_entry_missed')
                    });
                }
            }
        }

        getWorkflowViews() {
            const deferred = this.q.defer();
            const url = `/api/workflow-view/`;

            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        saveWorkflowView(item) {
            const deferred = this.q.defer();
            const url = `/api/workflow-view/${_.isNil(item.id) ? '' : `${item.id}/`}`;

            this.http.post(url, item)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        getColumnConfiguration() {
            return _.cloneDeep(this.columnConfiguration);
        }
    }

    module.exports = WorkFlowService;

})();
