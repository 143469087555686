/**
 * Created by BETALOS on 23/11/2016.
 */
(function () {

    'use strict';

    module.exports = DevicesCtrl;

    DevicesCtrl.$inject = ["interfacingService", "$state"];

    function DevicesCtrl(interfacingService, $state) {
        var vm = this;

        vm.$onInit = init;
        vm.handleDevice = handleDevice;

        function init() {
            vm.promise = interfacingService.getDevices()
                .then(success);

            function success(data) {
                vm.devices = data;
            }
        }


        function handleDevice(device) {
            $state.go('app.parameters.device-drivers', {device: device.id});
        }

    }

})();