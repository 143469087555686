/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    //@TODO clean fix after version release

    'use strict';

    const CALENDAR_DURATION_DIALOG = require('./calendar-duration-dialog');
    const APPOINTEMENT_AVAILABILITY_DIALOG = require('./appointement-availability-dialog');

    AppointmentDialogCtrl.$inject = ["$mdDialog", "system", "mnWebSocket", "patientService", "frontDeskService", "$state", "configService", "$q"];

    function AppointmentDialogCtrl($mdDialog, system, mnWebSocket, patientService, frontDeskService, $state, configService, $q) {
        let vm = this;

        const timeFormat = system['time_format'].js;
        const dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.submitPause = submitPause;

        vm.remove = remove;
        vm.createEntry = createEntry;
        vm.cancel = $mdDialog.cancel;

        vm.changeInterval = changeInterval;
        vm.showAvailabilities = showAvailabilities;
        vm.showDurationCalendar = showDurationCalendar;

        vm.query = query;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.handleTitleChange = patientService.handleTitleChange;

        vm.handleWaitingToggle = handleWaitingToggle;
        vm.checkToday = checkToday;

        function init() {
            vm.toPractice = false;

            vm.appointment = {
                start_time: moment().minutes(0).format(timeFormat),
                patient: {
                    file_date: moment().format(dateFormat),
                    profession: _.get(configService.defaultValues, "profession", null),
                    contact_info: _.assign({phone_numbers: []}, _.get(configService.defaultValues, "contact_info", null)),
                }
            };

            vm.pause = {};
            vm.is_pause = false;
            vm.is_event_edit = false;

            if (!_.isNil(vm.event)) handleEvent();
            if (!_.isNil(vm.timeRange)) handleTimeRange(vm.timeRange, true);

            if (!_.isNil(vm.patient)) {
                vm.is_event_edit = true;

                if (_.isNumber(vm.patient)) patientCallback({id: vm.patient});
                if (_.isObject(vm.patient)) patientCallback({id: vm.patient.id});
            }

            vm.hasPause = !!vm.hasPause || _.get(vm.event, 'is_pause', false);
            vm.is_pause = _.get(vm.event, 'is_pause', false);
        }

        function submit(is_toPatient, $event) {
            if (_.isUndefined(vm.appointment.patient.id)) return checkExistence();
            else return handleAppointment();

            function checkExistence() {
                let deferred = $q.defer();

                patientService.checkExistence(vm.appointment.patient).then(success, fail);

                function success() {
                    handleAppointment().then(deferred.resolve, deferred.reject);
                }

                function fail(data) {
                    const locals = {'patient-id': data.id};
                    const assign = {clickOutsideToClose: false, escapeToClose: false, multiple: true};

                    frontDeskService.entryResume(locals, $event, assign)
                        .then(usePatient, checkAppointmentHandle);

                    function usePatient() {
                        vm.appointment.patient = data;
                        success();
                    }

                    function checkAppointmentHandle(use) {
                        if (use) success();
                        else deferred.reject();
                    }
                }

                return deferred.promise;
            }

            function handleAppointment() {
                let deferred = $q.defer();

                vm.promise = frontDeskService.handleAppointment(vm.appointment)
                    .then(doneCallbacks, deferred.reject);

                return deferred.promise;

                function doneCallbacks(data) {
                    vm.appointment.id = data.id;

                    if (vm.toPractice) createEntry(vm.toPractice);
                    else {
                        mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
                        $mdDialog.hide(vm.appointment);
                    }

                    if (is_toPatient) $state.go("app.patient-form", {'patient_id': data.patient.id});

                    deferred.resolve(data);
                }
            }
        }


        function submitPause() {
            vm.promise = frontDeskService.handlePause(vm.pause)
                .then(doneCallbacks);

            return vm.promise;

            function doneCallbacks(data) {
                mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
                $mdDialog.cancel();
            }
        }

        function createEntry(stat) {
            frontDeskService.checkPatientEntry(vm.appointment.patient.id)
                .then(success, error);

            function success() {
                let wr = _.get(configService.defaultValues, "entry.waiting_room", null);
                let entry = frontDeskService.appointmentToEntry(vm.appointment, stat, wr);

                vm.promise = frontDeskService.handleEntry(entry)
                    .then(doneCallback);

                function doneCallback(data) {
                    mnWebSocket.pub("frontdesk.Practice.entry_added", data.entry, false);
                    mnWebSocket.pub("frontdesk.Calendar.notify", data.appointment, false);

                    $mdDialog.cancel();
                }
            }

            function error() {
                mnWebSocket.pub("frontdesk.Calendar.notify", vm.appointment, false);
                $mdDialog.cancel();
            }
        }

        function remove() {
            vm.promise = frontDeskService.removeAppointment(vm.appointment.id)
                .then(doneCallbacks);

            function doneCallbacks(data) {
                mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
                $mdDialog.cancel();
            }
        }

        function handleTimeRange(timeRange, timeout) {
            vm.appointment.date = vm.pause.date = timeRange.start.format(dateFormat);
            vm.appointment.start_time = vm.pause.start_time = timeRange.start.format(timeFormat);
            vm.appointment.end_time = vm.pause.end_time = timeRange.end.format(timeFormat);

            let agenda = timeRange.agenda;
            let physician = timeRange.physician;

            checkToday();

            if (timeout) setTimeout(setResource, 50);
            else setResource();

            function setResource() {
                if (agenda) vm.appointment.agenda = agenda;
                if (physician) vm.appointment.physician = vm.pause.physician = physician;
            }
        }

        function handleEvent() {
            if (vm.event.is_pause) {
                vm.is_event_edit = true;
                _.unset(vm, 'appointment');
                vm.pause = transformToPause(vm.event);
            }

            else if (_.has(vm.event, 'id')) {
                vm.is_event_edit = true;

                vm.promise = frontDeskService.getAppointment(vm.event.id)
                    .then(data => {
                        vm.is_today = (data.date === moment().format(dateFormat));
                        if (_.has(vm.event, "assignIn")) data = _.assign(data, vm.event.assignIn);
                        vm.appointment = data;
                    });

                _.unset(vm, 'pause');
            }

            else {
                if (vm.event.patient) vm.is_event_edit = true;
                if (vm.event.is_waiting_room) vm.hasPause = false;

                setTimeout(() => {
                    vm.appointment = _.assign(vm.appointment, vm.event);
                    changeInterval();
                }, 100)
            }
        }

        function changeInterval() {
            if (_.isNil(vm.appointment.start_time) || (vm.timeRange && vm.timeRange.isDrag)) return vm.timeRange = false;

            let duration = _.get(vm.appointment, 'reason.appointment_duration', 20);
            vm.appointment.end_time = moment(vm.appointment.start_time, timeFormat).add(duration, 'minutes').format(timeFormat);

            let defaultAgenda = _.get(configService.defaultValues, "appointment.agenda", null);
            let reasonAgenda = _.get(vm.appointment, 'reason.default_agenda');
            vm.appointment.agenda = reasonAgenda || defaultAgenda;
        }

        function showAvailabilities(ev) {
            let dialog = _.assign({}, APPOINTEMENT_AVAILABILITY_DIALOG, {
                targetEvent: ev,
                locals: {
                    query: {
                        days: 3,
                        start_date: vm.appointment.date || moment().format(dateFormat),
                        duration: _.get(vm.appointment, 'reason.appointment_duration', 20),
                        physician: _.get(vm.appointment, 'physician.id'),
                    }
                }
            });

            $mdDialog.show(dialog).then(success);

            function success(data) {
                _.assign(vm.appointment, data);
            }
        }

        function showDurationCalendar(ev) {
            let dialog = _.assign({}, CALENDAR_DURATION_DIALOG, {
                targetEvent: ev,
            });

            $mdDialog.show(dialog).then(data => {
                handleTimeRange(data, false);
            });
        }

        function query() {
            let nEqual = {"$ne": true};

            return _.chain(vm.appointment.patient).pick(['first_name', 'last_name']).assign({
                is_archived: nEqual,
                is_deleted: nEqual
            }).value();
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id)
                .then(success);

            function success(data) {
                vm.appointment.patient = data;
            }
        }

        function removePatient() {
            vm.appointment.patient = {};
        }

        function transformToPause(event) {
            return _.assign(event, {
                id: _.last(event.id.split('-')),
                date: event.start.format(dateFormat),
                start_time: event.start.format(timeFormat),
                end_time: event.end.format(timeFormat)
            });
        }


        function handleWaitingToggle() {
            vm.toPractice = false;

            if (!vm.appointment.is_waiting_room && _.isNil(vm.appointment.start_time)) {
                vm.appointment.start_time = moment().minutes(0).format(timeFormat);
                changeInterval();
            }
        }

        function checkToday() {
            vm.is_today = (vm.appointment.date === moment().format(dateFormat));
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AppointmentDialogCtrl,
        template: require("frontdesk/views/appointment-dialog.tpl.html"),
    };

})();