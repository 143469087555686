(function () {

    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        escapeToClose: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: PrintingImageDialogCtrl,
        template: require("../views/printing-image-dialog.tpl.html"),
    };

    PrintingImageDialogCtrl.$inject = ["$mdDialog"];

    function PrintingImageDialogCtrl($mdDialog) {
        let vm = this;

        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;

        vm.submit = submit;
        vm.imageAdded = imageAdded;
        vm.removePicture = removePicture;

        function init() {
            vm.files = [];
            vm.noTitle = _.isNil(vm.noTitle) ? false : vm.noTitle;
        }

        function submit() {
            let result = _.reduce(vm.files, function (result, file) {
                result.push({
                    name: file.savedName,
                    content: file.content
                })
                return result;
            }, []);
            $mdDialog.hide(result);
        }

        function removePicture(index, ev) {
            ev.stopPropagation();
            vm.files.splice(index, 1);
        }

        function imageAdded() {
            _.forEach(vm.files, function (file) {
                if (_.isNil(file.savedName)) {
                    file.savedName = fileName(file)
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.addEventListener("load", function () {
                        file.content = reader.result
                    }, false);
                }
            })
        }

        function fileName(file) {
            let tmp = file.name.split(".");
            tmp.pop();
            return tmp.join(".")
        }

    }

})();