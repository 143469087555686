/**
 * Created by BETALOS on 21/05/2016.
 */
(function () {

    'use strict';

    module.exports = PracticeCtrl;

    const ENTRY_DIALOG = require('../dialogs/entry-dialog');
    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');

    PracticeCtrl.$inject = ["$scope", "$state", "dragulaService", "$mdDialog", "mnWebSocket", "frontDeskService", "$timeout", "paymentService", "system", "$q", "practiceService", "configService", "visitService"];

    function PracticeCtrl($scope, $state, dragulaService, $mdDialog, mnWebSocket, frontDeskService, $timeout, paymentService, system, $q, practiceService, configService, visitService) {
        let vm = this;

        let namespace = _.has($scope, 'practice') ? 'practiceSide' : 'practiceModule';
        let dateTimeFormat = system['date_format'].naive;

        let initial = {};

        let bag = "practice-bag";
        let last_source = null;
        let drake = null;

        let exTimeout = null;

        vm.$onInit = init;

        vm.startedVisits = startedVisits;
        vm.endedVisits = endedVisits;

        vm.wrFilter = wrFilter;
        vm.cvFilter = cvFilter;
        vm.filterByStat = filterByStat;
        vm.checkFilter = checkFilter;
        vm.currentVisitLabel = currentVisitLabel;


        vm.isOpen = false;
        vm.handleExBlock = handleExBlock;
        vm.handleBlockIn = handleBlockIn;
        vm.handleBlockOut = handleBlockOut;

        vm.addEntry = addEntry;
        vm.payVisit = payVisit;
        vm.prePayVisit = prePayVisit;
        vm.payTreatmentPlans = payTreatmentPlans;

        vm.decideMethod = frontDeskService.decideMethod;

        vm.entryResume = entryResume;

        vm.callbacks = {
            patientOut: exit,
            payVisit: payVisit,
            prePayVisit: prePayVisit,
            editEntry: editEntry,
            patientFile: patientFile,
            payTreatmentPlans: payTreatmentPlans,
        };

        function init() {
            if (namespace === 'practiceModule') {
                dragulaService.options($scope, bag, {
                    copy: true,
                    moves: el => $(el).hasClass("draggable"),
                    invalid: el => $(el).hasClass("empty-list"),
                });

                drake = dragulaService.find($scope, bag).drake;

                drake.on("out", outContainer);
                drake.on("drop", dropElement);
                drake.on("over", overContainer);
                drake.on("shadow", shadowElement);

            } else filterObj['cv'] = _.assign(filterObj['cv'], {stat: 'SV'});

            vm.wr = [];
            vm.cv = [];

            vm.isDental = configService.isDental();

            vm.promise = $q.all([
                practiceService.updateEntries(),
                mnWebSocket.call("frontdesk.Practice.entry_count"),
                configService.get(['practice_payment', 'calendar_config']),
            ]).then(success);

            mnWebSocket.sub("frontdesk.Practice.entry_added", namespace, data => practiceService.updateEntries(data));
            mnWebSocket.sub("frontdesk.Practice.entry_canceled", namespace, data => practiceService.updateEntries(data));
            mnWebSocket.sub("frontdesk.Practice.entry_updated", namespace, data => practiceService.entryUpdated(data));
            mnWebSocket.sub("frontdesk.Practice.stat_changed", namespace, data => practiceService.entryUpdated(data));

            mnWebSocket.sub("frontdesk.Practice.entry_count", namespace, updateCount);

            let subscription = practiceService.entriesSubject.subscribe(getEntries);
            let visitSubLinksSubscription = visitService.visitSubLinks.subscribe(handleSubLinks);

            $scope.$on('$destroy', unSubscribe);

            function unSubscribe() {
                mnWebSocket.unsub("frontdesk.Practice.entry_added", namespace);
                mnWebSocket.unsub("frontdesk.Practice.entry_canceled", namespace);
                mnWebSocket.unsub("frontdesk.Practice.entry_updated", namespace);
                mnWebSocket.unsub("frontdesk.Practice.stat_changed", namespace);
                mnWebSocket.unsub("frontdesk.Practice.entry_count", namespace);

                subscription.unsubscribe();
                visitSubLinksSubscription.unsubscribe();
            }

            function success(data) {
                updateCount(data[1]);

                vm.paymentConfig = _.get(data, '2.practice_payment');
                vm.dayFilter = _.get(data, '2.calendar_config.practice_filter', true);
            }

            function handleSubLinks(data) {
                vm.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                vm.generateVisit = vm.callbacks.generateVisit = (entry, state) => {
                    return frontDeskService.generateVisit(entry, state ? state : vm.visitLinkConfig.link);
                }
            }
        }

        function getEntries(data) {
            initial.wr = data['WR'];
            initial.cv = data['CV'];

            vm.wr = filter('wr');
            vm.cv = filter('cv');

            $scope.$applyAsync();
        }

        function handleExBlock() {
            if (vm.isOpen) return;

            vm.isOpen = true;
            vm.exPromise = mnWebSocket.call("frontdesk.Practice.exit_entries")
                .then(data => vm.ex = data);
        }

        function handleBlockIn() {
            if (exTimeout) $timeout.cancel(exTimeout);
        }

        function handleBlockOut() {
            exTimeout = $timeout(() => {
                vm.ex = [];
                vm.isOpen = false;
            }, 30000);
        }


        function updateCount(count) {
            _.assign(vm, count);
        }

        function addEntry(ev) {
            $mdDialog.show(_.assign({}, ENTRY_DIALOG, {
                targetEvent: ev,
                locals: {entryTmp: null}
            })).then(data => practiceService.entryAdded(data), _.noop);
        }

        function editEntry(item, ev) {
            frontDeskService.editEntry(item, ev)
                .then(data => practiceService.entryUpdated(data));
        }

        function entryResume(item, ev) {
            const locals = {
                'entry': item,
                'patient-id': _.get(item, 'patient.id')
            };

            frontDeskService.entryResume(locals, ev);
        }

        function payVisit($event, entry) {
            paymentService.payEntry($event, entry);
        }

        function prePayVisit($event, entry) {
            paymentService.prePayEntry($event, entry);
        }

        function payTreatmentPlans($event, entry) {
            paymentService.payDentalTreatmentPlan($event, entry);
        }

        function overContainer(el, container, e_source) {
            //@TODO style-over
            last_source = $(container).data('container');
            if (container !== e_source) $(container).addClass("selected-container");
        }

        function outContainer(el, container, e_source) {
            //@TODO style-out
            last_source = null;
            if (container !== e_source) $(container).removeClass("selected-container");
        }

        function shadowElement(el) {
            $(el).remove();
        }

        function dropElement(el, e_target, e_source) {
            $(e_target).removeClass("selected-container");

            const id = $(el).data('id');
            const source = $(e_source).data('container');
            const target = $(e_target).data('container');

            if (last_source == source) return;

            let item = getItemById(id, source);

            vm.decideMethod(item, source, target);
        }

        function exit(item, source) {
            changeEntryStat(item, source, "ex")
                .then(() => mnWebSocket.pub("frontdesk.Practice.entry_count", {}, false));
        }

        function patientFile(item) {
            return $state.go("app.patient-form", {'patient_id': item.patient.id});
        }

        function getItemById(id, container) {
            //@TODO optimize
            const cnt = _.eq(container, 'wr') ? container : 'cv';
            return _.chain(initial).get(cnt, []).find({id}).value();
        }

        function changeEntryStat(item, source, target) {
            return frontDeskService.updateEntry(item.id, source, target);
        }

        // count
        function startedVisits() {
            return _.reduce(initial['cv'], (sum, item) => {
                if (_.eq(item['stat'], 'SV')) return sum + 1;
                else return sum;
            }, 0);
        }

        function endedVisits() {
            return _.reduce(initial['cv'], (sum, item) => {
                if (_.eq(item['stat'], 'EV')) return sum + 1;
                else return sum;
            }, 0);
        }

        // filters
        let filterObj = {};

        function wrFilter(list) {
            filterObj['wr'] = _.assign(filterObj['wr'], list);
            vm['wr'] = filter('wr');
        }

        function filterByStat(stat, ev) {
            ev.stopPropagation();

            if (_.has(filterObj['cv'], 'stat') && filterObj['cv']['stat'] === stat) _.unset(filterObj['cv'], 'stat');
            else filterObj['cv'] = _.assign(filterObj['cv'], {stat: stat});
            vm['cv'] = filter('cv');
        }

        function checkFilter(stat) {
            return _.get(filterObj, 'cv.stat', null) === stat;
        }

        function currentVisitLabel() {
            return _.get(filterObj, 'cv.stat', "practice_visit_list");
        }

        function cvFilter(list) {
            filterObj['cv'] = _.assign(filterObj['cv'], list);
            vm['cv'] = filter('cv');
        }

        function filter(array) {
            let list = [];

            if (_.isEmpty(filterObj[array])) list = initial[array];

            else list = _.filter(initial[array], (item) => {
                return _.reduce(filterObj[array], (test, value, key) => test && _.includes(value, _.get(item, key)), true);
            });

            return array == "wr" ? orderWr(list) : orderCV(list);
        }

        //order entries
        function orderWr(list) {
            return _.orderBy(list, [
                item => item['is_urgent'] ? 1 : 0,
                item => moment(item['entry_time'], dateTimeFormat).valueOf()
            ], ["desc", "asc"]);
        }

        function orderCV(list) {
            return _.sortBy(list, item => {
                if (item.stat == 'SV') return moment(item['visit_start_time'], dateTimeFormat).valueOf();
                if (item.stat == 'EV') return moment(item['visit_end_time'], dateTimeFormat).valueOf();
            });
        }
    }

})();
