(function () {

    'use strict';

    module.exports = {
        controller: ViewerDialogController,
        controllerAs: "vm",
        template: require("parameters/views/dcm-viewer-dialog.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true,
        multiple: true,
    };

    ViewerDialogController.$inject = ["$mdDialog", "dcmService"];

    function ViewerDialogController($mdDialog, dcmService) {
        var vm = this;

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            dcmService.handleViewer(vm.viewer)
                .then($mdDialog.hide);
        }

    }

})();