/**
 * Created by BETALOS on 24/09/2016. [edited by amine 16/10/2019]
 */

(function () {

    'use strict';

    const AGENDA_FORM_DIALOG = require('../dialogs/agenda-form-dialog');

    class AgendaCtrl {
        constructor(frontDeskService, $timeout, $mdDialog) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.frontDeskService = frontDeskService;

            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["frontDeskService", "$timeout", "$mdDialog"];
        }

        $onInit() {
            this.subscriber = this.frontDeskService.agendaSubject
                .subscribe(data => this.items = _.chain(data).filter(['is_disabled', false]).sortBy('name').value());

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onDestroy() {
            if (this.subscriber) this.subscriber.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            this.dialog.show(_.assign({}, AGENDA_FORM_DIALOG, {
                targetEvent: ev,
                locals: {},
            })).then(data => {
                this.items = _.chain(this.items).concat(data).sortBy("description").value();

                this.internalValue = data;
                this.internalValueChanged();
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, $attrs) {
        const multiple = $elem.is('[multiple]') ? 'multiple' : "";
        const label = _.isUndefined($attrs['label']) ? "agenda_label" : $attrs['label'];
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const isRequired = $elem.is("[required]") ? " required " : "";
        const ngDisabled = !_.isUndefined($attrs['ngDisabled']) ? 'ng-disabled="vm.disabled"' : "";
        const isDisabled = $elem.is('[disabled]') ? " disabled " : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
                <md-input-container>
                    <label translate-once="${label}"></label>
                    <md-select ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id' }" 
                            ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled} ${multiple}>
                        <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                            <span ng-bind="::item.name" class="flex"></span>
                        </md-option>
                    </md-select>
                    <div class="mn-option-buttons flex-nogrow layout-row">
                        <md-button ${isDisabled} ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                            <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                        </md-button>
                        <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                            <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                        </md-button>
                    </div>
                </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: AgendaCtrl,
        require: {ngModelController: "ngModel"}
    }

})();