/**
 * Created by BETALOS on 31/12/2016.
 */
(function () {

    'use strict';

    module.exports = GeneralConfigCtrl;

    GeneralConfigCtrl.$inject = ['$scope', 'configService', 'dragulaService', '$mdToast', '$translate', '$timeout', 'visitService', 'dictionaryService', 'WorkFlowService', 'frontDeskService'];

    function GeneralConfigCtrl($scope, configService, dragulaService, $mdToast, $translate, $timeout, visitService, dictionaryService, WorkFlowService, frontDeskService) {
        let vm = this;
        let bag = "item-bag";

        let items = require('../json/visit-sub-links.json');
        let entryTabs = require('../json/entry-resume-tabs.json');

        vm.$onInit = init;

        vm.handleConfig = _.mnDelay(handleConfig, 750);
        vm.updateDefault = _.mnDelay(startAutoSave, 750);
        vm.handlePrescriptionFieldsConfig = _.mnDelay(handlePrescriptionFieldsConfig, 750);

        vm.entryResumeTab = _.mnDelay(entryResumeTab, 750);
        vm.handleWorkFlowConfig = _.mnDelay(handleWorkFlowConfig, 750);
        vm.handlePatientAlertField = _.mnDelay(handlePatientAlertField, 750);
        vm.handleNotificationField = _.mnDelay(handleNotificationField, 750);
        vm.handleOphtalmicFieldsConfig = _.mnDelay(handleOphtalmicFieldsConfig, 750);

        vm.handleWorkflowView = handleWorkflowView;
        vm.workflowColumnLabel = workflowColumnLabel;
        vm.workflowFormGoBack = workflowFormGoBack;
        vm.workflowFormSubmit = workflowFormSubmit;

        //just a hack
        let firstInit = true;
        let simpleToast = $mdToast.simple()
            .textContent($translate['instant']('exam_setup_edit_success'))
            .position("bottom left")
            .hideDelay(1500);

        vm.setFavorite = setFavorite;

        function init() {
            vm.hasStock = configService.hasStock();
            vm.hasOphthalmic = _.get(configService.activeModules, 'has_ophthalmic_prescription', false);
            vm.workflowColumns = WorkFlowService.getColumnConfiguration();
            vm.dictionaryGroup = dictionaryService.getDictionaryGroups();

            frontDeskService.agendaSubject
                .subscribe(data => vm.frontDeskAgendas = _.chain(data).filter(['is_disabled', false]).sortBy('name').value());

            WorkFlowService.getWorkflowViews()
                .then(data => {
                    vm.workflowViews = data;
                });

            vm.promise = configService.get([
                "visit_links_config",
                "prescription_fields_config",
                "ophtalmic_fields_config",
                "patient_alert_field",
                "notification_field",
                "work_flow_config",
                "entry_resume_default_tab",
                "work_flow_config"
            ], true).then(success);

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            const drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(handleOrder, 400));

            dragulaService.options($scope, 'workflow-bag', {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            //const workflowDrake = dragulaService.find($scope, 'workflow-bag').drake;
            //workflowDrake.on("dragend", _.mnDelay(handleOrder, 400));

            function success(data) {
                if (!configService.isDental()) {
                    _.remove(entryTabs, 'isDental');
                    _.remove(items, ['key', 'DENTAL']);
                    _.remove(items, ['key', 'PERIODONTAL']);
                }

                vm.config = data['visit_links_config'];
                vm.patientAlertField = data['patient_alert_field'];
                vm.notificationField = data['notification_field'];
                vm.workFlowConfig = data['work_flow_config'] || {};
                vm.ophtalmicFieldsConfig = data['ophtalmic_fields_config'] || {};
                vm.prescriptionFieldsConfig = data['prescription_fields_config'] || {};
                vm.entryResumeDefaultTab = data['entry_resume_default_tab'] || 'PATIENT_DEMOGRAPHICS';
                vm.workflowForm = false;
                vm.workflowConfig = data['work_flow_config'] || {};

                vm.resumeTabs = entryTabs;
                vm.visitSubLinks = vm.config['order'] ? _.orderBy(items, item => _.get(data, `order['${item.key}']`)) : items;
            }
        }

        function startAutoSave() {
            configService.set({"default_values": vm.default}, true)
                .then(done);

            function done() {
                configService.defaultValues = vm.default;
                saveMsg();
            }
        }

        function handleConfig() {
            configService.set({"visit_links_config": vm.config}, true)
                .then(done);

            function done() {
                visitService.visitSubLinks.next(vm.config);
                saveMsg();
            }
        }

        function handlePrescriptionFieldsConfig() {
            configService.set({"prescription_fields_config": vm.prescriptionFieldsConfig}, true)
                .then(saveMsg);
        }

        function handleOphtalmicFieldsConfig() {
            configService.set({"ophtalmic_fields_config": vm.ophtalmicFieldsConfig}, true)
                .then(saveMsg);
        }

        function handlePatientAlertField() {
            configService.set({"patient_alert_field": vm.patientAlertField}, true)
                .then(saveMsg);
        }

        function handleNotificationField() {
            configService.set({"notification_field": vm.notificationField}, true)
                .then(saveMsg);
        }

        function handleWorkFlowConfig() {
            configService.set({"work_flow_config": vm.workFlowConfig}, true)
                .then(saveMsg);
        }

        function entryResumeTab() {
            configService.set({"entry_resume_default_tab": vm.entryResumeDefaultTab}, true)
                .then(saveMsg);
        }

        function handleOrder() {
            vm.config['order'] = _.reduce(
                vm.visitSubLinks, (config, item, key) => _.assign({}, config, {[item.key]: key}), {}
            );

            handleConfig();
        }

        function handleWorkflowView(item) {
            if (_.isNil(item)) {
                vm.workflowModel = {
                    agendas: [],
                    columns: _.map(vm.workflowColumns, column => {
                        return _.chain(column).pick(['key']).set('disabled', false).value();
                    })
                };
            } else {
                vm.workflowModel = item;
            }
            vm.workflowForm = true;
        }

        function workflowColumnLabel(column) {
            return _.chain(vm.workflowColumns).find(['key', column.key]).get("label", "---").value()
        }

        function workflowFormGoBack() {
            vm.workflowModel = null;
            vm.workflowForm = false;
        }

        function workflowFormSubmit() {
            WorkFlowService.saveWorkflowView(vm.workflowModel)
                .then(() => {
                    WorkFlowService.getWorkflowViews()
                        .then(data => {
                            vm.workflowViews = data;
                        });
                });
        }

        function setFavorite(subLink) {
            vm.config['favorite'] = subLink.key;
            vm.handleConfig();
        }


        function saveMsg() {
            if (firstInit) firstInit = false;
            else $timeout(() => $mdToast.show(simpleToast), 100);
        }

    }

})();
