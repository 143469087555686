/**
 * Created by amine on 13/05/2018.
 */
(function () {

    'use strict';

    module.exports = ExamModelFormSetupCtrl;

    ExamModelFormSetupCtrl.$inject = ["$scope", "examService", "$state", "$transition$"];

    function ExamModelFormSetupCtrl($scope, examService, $state, $transition$) {
        var vm = this;

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;
        vm.saveModel = saveModel;
        vm.deleteModel = examService.deleteExamModel;
        vm.cancel = examService.templateListState;

        function init() {
            vm.modelId = _.get($transition$.params('to'), 'modelId', null);
            vm.template = _.get($transition$.params('to'), 'template', null);
            vm.copy = _.get($transition$.params('to'), 'copy', null);

            vm.editorName = _.uniqueId('exam-editor-');
            vm.editorConfigKey = "exam_editor";
            vm.editorOpts = {}

            examService.sideNaveSubject.next(true);
            examService.selectedTemplate.next(vm.template);

            if (_.isNil(vm.modelId) && _.isNil(vm.copy)) newModel();
            else if (_.isNil(vm.model) && !_.isNil(vm.copy)) examService.getExamModel(vm.copy).then(duplicate, error);
            else examService.getExamModel(vm.modelId).then(edit, error);

            function edit(data) {
                vm.model = data;
                examService.currentModelSubject.next({id: vm.model.id, type: "edit"});
                examService.selectedTemplate.next(vm.model.template);
            }

            function duplicate(data) {
                vm.model = {
                    title: data.title + " (COPY)",
                    content: data.content,
                    template: data.template
                }
                examService.currentModelSubject.next({id: data.id, type: 'duplicate'});
                examService.selectedTemplate.next(vm.model.template);
            }

            function newModel(){
                vm.model = {
                    title: "",
                    content: "",
                    template: {id: vm.template}
                }
                examService.currentModelSubject.next({id: null, type: null});
                examService.selectedTemplate.next(vm.template);
            }

            function error() {
                if (vm.visitContext) examService.listState();
            }
        }

        function saveModel(quit) {
            return examService.saveExamModel(vm.model)
                .then(success);

            function success(data) {
                if (_.isNil(vm.model.id)) {
                    vm.model.id = data.id;
                    examService.currentModelSubject.next({id: data.id, type: 'edit'});

                    if (!quit) examService.formModelState(data.template, data, false);
                }

                if (quit) examService.templateListState();
            }
        }

        function onDestroy() {
        }
    }

})();
