(function () {

    'use strict';

    module.exports = AppBackupCtrl;

    AppBackupCtrl.$inject = ["$http", "$mdDialog", "$translate", "$auth"];

    function AppBackupCtrl($http, $mdDialog, $translate, $auth) {
        var vm = this;

        vm.$onInit = init;
        vm.download = download;
        vm.newBackup = newBackup;
        vm.removeBackup = removeBackup;

        function init() {
            vm.promise = $http.get('/api/app-backup/')
                .then(success);

            function success(response) {
                vm.backups = response.data;
            }
        }

        function newBackup(ev) {
            var confirm = $mdDialog.confirm()
                .title($translate['instant']('app_backups_add_confirm'))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(generate);

            function generate() {
                vm.promise = $http.post('/api/app-backup/', {})
                    .then(success);
            }

            function success(response) {
                vm.backups = _.pushOrUpdate(vm.backups, response.data);
            }
        }

        function removeBackup(backup, ev) {
            var confirm = $mdDialog.confirm()
                .title($translate['instant']('app_backups_remove_confirm', backup))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = $http.delete('/api/app-backup/' + backup.id + '/')
                    .then(success);
            }

            function success() {
                _.remove(vm.backups, backup);
            }
        }

        function download(backup) {
            return _.format('/api/app-backup/{0}/download/?auth={1}', backup.id, $auth.getToken());
        }

    }

})();
