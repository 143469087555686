/**
 * Created by BETALOS on 05/01/2017.
 */
(function () {

    'use strict';


    class HtmlFieldDialogCtrl {
        constructor($mdDialog, blockService) {
            this.dialog = $mdDialog;
            this.blockService = blockService;

            this.fieldsTypes = this.fieldsTypes || null;
            this.dictionaryGroup = this.dictionaryGroup || null;
            this.autocompleteFields = this.autocompleteFields || null;
        }

        static get $inject() {
            return ["$mdDialog", "blockService"];
        }

        $onInit() {
            this.promise = this.blockService.getHtmlFields()
                .then(data => {
                    this.initList = data;
                    this.fields = _.reduce(data, (list, item) => {
                        let type, detail;
                        let field = _.pick(item, ['id', 'label', 'is_system']);

                        if (item['type'] === 'dictionary') {
                            type = _.find(this.fieldsTypes, {value: 'dictionary'}).label;
                            detail = _.find(this.dictionaryGroup, {uid: item['meta_data']['dict_uid']});

                            if (detail) detail = detail.value;
                        }

                        if (item['type'] === 'autocomplete') {
                            type = _.find(this.fieldsTypes, {value: 'autocomplete'}).label;
                            detail = _.find(this.autocompleteFields, item['meta_data']);

                            if (detail) detail = detail.label;
                        }

                        if (detail) list.push(_.assign(field, {
                            type: type, detail: detail
                        }));

                        return list;
                    }, []);
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        resolveItem(item) {
            let field = _.find(this.initList, _.pick(item, 'id'))
            this.dialog.hide(field);
        }
    }

    const DRAG_BAG = "field-bag";
    const BLOCK_SETUP_PARAMS = require('../json/block-setup-params.json');
    const HTML_FIELD_DIALOG = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: HtmlFieldDialogCtrl,
        template: require("../views/html-field-dialog.tpl.html"),
    };

    class BlockSetupFormCtrl {
        constructor(
            $state, $transition$, dictionaryService, blockService, $mdDialog, dragulaService, $scope, $mdToast,
            $translate, $q
        ) {
            this.q = $q;
            this.scope = $scope;
            this.state = $state;
            this.toast = $mdToast;
            this.dialog = $mdDialog;
            this.transition$ = $transition$;
            this.blockService = blockService;
            this.dragulaService = dragulaService;

            this.fieldsTypes = BLOCK_SETUP_PARAMS["fields-types"];
            this.autocompleteFields = BLOCK_SETUP_PARAMS["autocomplete-fields"];
            this.dictionaryGroup = dictionaryService.getDictionaryGroups();

            this.simpleToast = this.toast.simple()
                .hideDelay(2000)
                .position("bottom left")
                .textContent($translate['instant']('exam_setup_edit_success'));
        }

        static get $inject() {
            return [
                "$state", "$transition$", "dictionaryService", "blockService", "$mdDialog", "dragulaService",
                "$scope", "$mdToast", "$translate", "$q"
            ];
        }

        $onInit() {
            let promises = [this.blockService.getChoiceListFields()];
            let currentBlock = _.get(this.transition$.params('to'), 'block');

            if (currentBlock) promises.push(this.blockService.getBlock(currentBlock, 'html'));
            else promises.push({html_fields: [], is_system: false});

            this.promise = this.q.all(promises)
                .then(data => {
                    this.block = data[1];
                    this.readOnly = data[1]['is_system'];

                    this.choiceFields = _.filter(data[0], ['type', 'choice-list']);
                });

            this.dragulaService.options(this.scope, DRAG_BAG, {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
        }

        goBack() {
            this.state.go('app.parameters.block-setup');
        }


        addNewField() {
            this.block.html_fields.push({});
        }

        handleBlock() {
            this.blockService.handleBlock(this.block, 'html')
                .then(data => {
                    this.block.id = data.id;
                    this.block.key = data.key;

                    this.state.go("app.parameters.block-setup-form", {block: data.id});
                    setTimeout(() => this.toast.show(this.simpleToast), 100);
                });
        }

        getField(index, ev) {
            const dialog = _.chain(HTML_FIELD_DIALOG)
                .cloneDeep()
                .assign({
                    targetEvent: ev,
                    locals: {
                        fieldsTypes: this.fieldsTypes,
                        dictionaryGroup: this.dictionaryGroup,
                        autocompleteFields: this.autocompleteFields,
                    }
                })
                .value();

            this.dialog.show(dialog).then(field => {
                this.block['html_fields'].splice(index, 1, field);
            });
        }

        changeField(field) {
            _.unset(field, 'id');
        }

        changeFieldType(field) {
            this.changeField(field);
            field.meta_data = {};
        }

        removeField(index) {
            this.block['html_fields'].splice(index, 1);
        }

        emptyField(field, key, event) {
            event.stopPropagation()
            _.set(field, key, null);
        }

    }

    module.exports = BlockSetupFormCtrl;

})();