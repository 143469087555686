(function () {

    'use strict';

    module.exports = oximetryService;

    oximetryService.$inject = ["$http", "$q", "mnWebSocket", "system"];

    function oximetryService($http, $q, mnWebSocket, system) {
        var self = this;
        self.saveExam = saveExam;
        self.getExams = getExams;
        self.default_config = default_config;
        self.getDateUint8Array=getDateUint8Array;
        self.readSFLOAT=readSFLOAT;
        self.newDateFromArray=newDateFromArray;
        self.toInt16=toInt16;
        var fullDateTimeFormat = system['full_datetime_format'].js;

        function saveExam(_exam) {
            var deferred = $q.defer();
            var url = `/api/oximetry-exam/${!_.isNil(_exam.id) ? _exam.id + "/" : ""}`;
            $http.post(url, _exam)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getExams(_id) {
            return mnWebSocket.call('oximetry.OximetryExam.get_exams', {_pk: _id});
        }

        function default_config() {
            return mnWebSocket.call('oximetry.OximetryExam.get_default_config');
        }


        //******* utils**********
        function toInt16(num_arr) {
            // number in their orders must be giving
            return parseInt(("00000000" + num_arr[1].toString(2)).slice(-8).concat(("00000000" + num_arr[0].toString(2)).slice(-8)), 2);
        }

        function getDateUint8Array(d) {
            return _.concat(toInt16Array(d.getFullYear()),
                [d.getMonth()+1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), 0, 0, 0])
        }

        function toInt16Array(num1) { //2018 =>[226,7]
            return _.chain((("00000000" + num1.toString(2)).replace(/^0+/, '')).match(/.{1,8}(?=(.{8})+(?!.))|.{1,8}$/g)).reverse().map(function (e) {
                return parseInt(e, 2)
            }).value()
        }

        function  readSFLOAT(dv, offset = 0, littleEndian = false) {
            // if reserving the same order of your data littleIndian = true as i give 2 unit8  offset=0
            // ex :dv=new DataView( Uint8Array.of(75,0).buffer || new Uint8Array([75,0]))
            var data = dv.getUint16(offset, littleEndian);
            var mantissa = (data & 0x0FFF);
            var exponent = data >> 12;
            if ((mantissa & 0x0800) > 0) {
                mantissa = -1 * (~(mantissa - 0x01) & 0x0FFF)
            }
            if (exponent >= 0x0008) {
                exponent = -((0x000F + 1) - exponent);
            }
            return mantissa * Math.pow(10, exponent);
        }

        function newDateFromArray(args, scope) {
            // args  ex [2018,7,16,15,56,59]
            return new (Function.prototype.bind.apply(Date, [scope].concat(args)));
        }


    }
})();