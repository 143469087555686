/**
 * Created by BETALOS on 23/11/2016.
 */
(function () {

    'use strict';

    module.exports = DeviceDriversCtrl;

    DeviceDriversCtrl.$inject = ["interfacingService", "$stateParams", "$mdDialog", "$state"];

    function DeviceDriversCtrl(interfacingService, $stateParams, $mdDialog, $state) {
        var vm = this;

        var currentDevice = $stateParams.device

        var dialog = {
            controller: DeviceDriversDialogCtrl,
            controllerAs: "vm",
            template: require("parameters/views/device-driver-dialog.tpl.html"),
            parent: $(document.body),
            locals: {
                device: currentDevice
            },
            bindToController: true,
            clickOutsideToClose: false
        };

        vm.drivers = [];
        vm.$onInit = init;

        vm.newDriver = newDriver;
        vm.editDriver = editDriver;
        vm.removeDriver = removeDriver;

        vm.goBack = goBack;

        function init() {
            interfacingService.getDeviceDetail(currentDevice)
                .then(success);

            interfacingService.activeDevice = currentDevice;
            interfacingService.deviceConfigUpdates(success);

            function success(data) {
                vm.device = data;
                vm.drivers = data['device_active_configs'];
            }
        }

        function goBack() {
            $state.go('app.parameters.devices');
        }


        function newDriver(ev) {
            dialog.targetEvent = ev;
            dialog.locals.driver = null;
            $mdDialog.show(dialog);
        }

        function editDriver(driver, ev) {
            dialog.targetEvent = ev;
            dialog.locals.driver = driver;
            $mdDialog.show(dialog);
        }

        function removeDriver(driver) {
            interfacingService.removeDeviceConfig(currentDevice, driver)
        }

    }

    DeviceDriversDialogCtrl.$inject = ["$mdDialog", "interfacingService"];

    function DeviceDriversDialogCtrl($mdDialog, interfacingService) {
        var vm = this;

        vm.submit = submit;
        vm.selectDriver = selectDriver;
        vm.cancel = $mdDialog.cancel;

        init()

        function init() {
            if (!_.isNull(vm.driver)) vm.defaultDriver = {id: vm.driver['default_config_id']};
            else vm.driver = {};
        }

        function selectDriver() {
            if (_.eq(vm.defaultDriver['id'], vm.driver['default_config_id'])) return;
            else vm.driver = vm.defaultDriver;
        }

        function submit() {
            interfacingService.handleDeviceConfig(vm.device, vm.driver)
                .then($mdDialog.cancel);
        }

    }

})();