/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {

    'use strict';


    const STAFF_DIALOG = require('auth/dialogs/staff-dialog');

    class AccountsSetupCtrl {
        constructor(actorService, $mdDialog, authService, system) {
            this.dialog = $mdDialog;
            this.authService = authService;
            this.actorService = actorService;

            this.totalAccounts = system['allowed_accounts'];
        }

        static get $inject() {
            return ["actorService", "$mdDialog", "authService", "system"];
        }

        $onInit() {
            this.promise = this.actorService.getStaffList()
                .then(data => this.staffs = _.orderBy(data, item => [item.is_active ? 0 : 1, _.lowerCase(item.full_name)], ['asc', 'asc']));
        }

        handleAccount(staff, ev) {
            if (this.getAvailable() == 0 && !staff) return;

            this.dialog.show(_.assign({}, STAFF_DIALOG, {
                targetEvent: ev,
                locals: {
                    staff: _.cloneDeep(staff)
                }
            })).then(data => {
                this.staffs = _.chain(this.staffs)
                    .pushOrUpdate(data)
                    .orderBy(data, item => [item.is_active ? 0 : 1, _.lowerCase(item.full_name)], ['asc', 'asc'])
                    .value()
            });
        }

        getAvailable() {
            return this.totalAccounts - _.sumBy(this.staffs, staff => staff.is_active ? 1 : 0);
        }
    }

    module.exports = AccountsSetupCtrl;

})();