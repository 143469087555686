/**
 * Created by amine on 12/04/2018.
 */
(function () {
    'use strict';

    module.exports = VaccinationCalendarSetupCtrl;

    VaccinationCalendarSetupCtrl.$inject = ["vaccinationService", "$translate", "$mdDialog"];

    function VaccinationCalendarSetupCtrl(vaccinationService, $translate, $mdDialog) {
        let vm = this;
        let vaccineDialog = require("specifics/dialogs/vaccine-form-dialog");
        let configDialog = require("specifics/dialogs/vaccination-config-form-dialog");

        vm.$onInit = init;
        vm.cancel = cancel;

        vm.onVaccinesReorder = onVaccinesReorder;
        vm.onVaccinesPaginate = onVaccinesPaginate;
        vm.getVaccinesData = getVaccinesData;
        vm.newVaccine = vaccineForm;
        vm.editVaccine = vaccineForm;
        vm.removeVaccine = removeVaccine;

        vm.onConfigsReorder = onConfigsReorder;
        vm.onConfigsPaginate = onConfigsPaginate;
        vm.getConfigsData = getConfigsData;
        vm.vaccinationConfigForm = configForm;
        //vm.editVaccinationConfig = configForm;
        vm.removeVaccinationConfig = removeVaccinationConfig;

        function init() {
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            vm.options = [20, 15, 10, 5];

            vm.promise = null;

            vm.vaccines = [];
            vm.listTotal = 0;
            vm.listQuery = {
                limit: 10,
                page: 1,
                order: "+id",
                search_all: ""
            }

            vm.configs = [];
            vm.configsTotal = 0;
            vm.configsQuery = {
                limit: 10,
                page: 1,
                search_all: ""
            }

            getVaccinesData();
            getConfigsData();
        }

        function onVaccinesReorder(order) {
            vm.listQuery = _.assign(vm.listQuery, {order: order});
            getVaccinesData();
        }

        function onVaccinesPaginate(page, limit) {
            vm.listQuery = _.assign(vm.listQuery, {page: page, limit: limit});
            getVaccinesData();
        }

        function getVaccinesData() {
            vm.promise = vaccinationService
                .vaccinesList(vm.listQuery)
                .then(done);

            function done(data) {
                vm.listTotal = data.length;
                vm.vaccines = data.list;

                if (vm.listTotal < ((vm.listQuery.page - 1) * vm.listQuery.limit))
                    vm.listQuery.page = 1;
            }
        }

        function vaccineForm(vaccine, event) {
            $mdDialog
                .show(_.assign(vaccineDialog, {
                    targetEvent: event,
                    locals: {vaccine: vaccine}
                }))
                .then(getVaccinesData);
        }

        function removeVaccine(vaccine) {
            vaccinationService.removeVaccine(vaccine)
                .then(getVaccinesData);
        }

        function configForm(config, event) {
            $mdDialog
                .show(_.assign(configDialog, {
                    targetEvent: event,
                    locals: {config: config}
                }))
                .then(getConfigsData);
        }

        function onConfigsReorder() {
            //vm.configsQuery = _.assign(vm.configsQuery, {order: order});
            getVaccinesData();
        }

        function onConfigsPaginate(page, limit) {
            vm.configsQuery = _.assign(vm.configsQuery, {page: page, limit: limit});
            getConfigsData();
        }

        function getConfigsData() {
            vm.promise = vaccinationService
                .calendarConfigsList(vm.configsQuery)
                .then(done);

            function done(data) {
                vm.configsTotal = data.length;
                vm.configs = data.list;

                if (vm.configsTotal < ((vm.configsQuery.page - 1) * vm.configsQuery.limit))
                    vm.configsQuery.page = 1;
            }
        }

        function removeVaccinationConfig(config) {
            vaccinationService.removeVaccinationConfig(config)
                .then(getConfigsData);
        }

        function cancel() {
            if (vm.dialogContext) $mdDialog.cancel();
        }
    }
})();
