/**
 * Created by Betalos on 18/06/2017.
 */
(function () {

    'use strict';

    module.exports = ListCtrl;

    ListCtrl.$inject = ["listService", "dragulaService", "$scope", "$mdDialog", "$mdToast", "$translate", "$timeout"];

    function ListCtrl(listService, dragulaService, $scope, $mdDialog, $mdToast, $translate, $timeout) {
        let vm = this;

        let bag = "item-bag";

        vm.$onInit = init;
        vm.viewList = viewList;

        vm.orderList = orderList;
        vm.handleItem = handleItem;
        vm.updateItem = updateItem;
        vm.removeItem = removeItem;

        function init() {
            vm.currentList = null;
            vm.items = [];
            vm.models = require('../json/list-models.json');

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let drake = dragulaService.find($scope, bag).drake;

            drake.on("dragend", _.mnDelay(endSort, 400));
        }

        function viewList(list) {
            vm.currentList = list;

            vm.promise = listService.list(list, false)
                .then(success);

            function success(data) {
                vm.items = data;
            }
        }

        function orderList(order) {
            vm.items = _.orderBy(vm.items, item => _.chain(item.value).toLower().deburr().value(), order);
            endSort();
        }

        function endSort() {
            const sortedItems = _.map(vm.items, (item, index) => {
                return _.assign({
                    order: index
                }, _.pick(item, 'id'));
            });

            listService.reorderList(sortedItems, vm.currentList)
                .then(updateSuccess);
        }

        function updateItem(item) {
            let item_copy = _.assign(item, {model_name: vm.currentList});

            listService.handleList(item_copy)
                .then(updateSuccess);
        }

        function handleItem(item, ev) {
            $mdDialog.show(_.assign(require('shared/dialogs/list-dialog'), {
                targetEvent: ev,
                locals: {
                    list: _.cloneDeep(item),
                    mnLabel: "",
                    mnModel: vm.currentList
                },
            })).then(done);

            function done(data) {
                vm.items = _.pushOrUpdate(vm.items, data);
            }
        }

        function updateSuccess() {
            let simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $timeout(function () {
                $mdToast.show(simpleToast);
            }, 100);
        }

        function removeItem(item, event) {
            listService.removeItem(item, event)
                .then(success);

            function success() {
                let index = _.findIndex(vm.items, {id: item.id});
                vm.items.splice(index, 1);
            }
        }

    }

})();