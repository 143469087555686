/**
 * Created by BETALOS on 04/07/2017.
 */
(function () {

    'use strict';

    const RESOURCE_CHOICES = ['create', 'get', 'update', 'delete'];
    const PROFILE_RESOURCES_SORT = i => _.isString(i) ? i : _.get(i, 'key');
    const PROFILE_RESOURCES = _.sortBy(require('../json/resources.json'), PROFILE_RESOURCES_SORT);

    class ProfileSetupFormCtrl {
        constructor(authService, $transition$, $state, $mdToast, $translate, $timeout) {
            this.$state = $state;
            this.toast = $mdToast;
            this.$timeout = $timeout;
            this.authService = authService;


            this.currentProfile = _.get($transition$.params('to'), 'profile');
            this.examSetupEditSuccess = $translate['instant']('exam_setup_edit_success');

            this.accessLevels = ['none', 'mine', 'service', 'all'];

            this.filteredResources = [];
            this.resources = _.map(PROFILE_RESOURCES, PROFILE_RESOURCES_SORT);
        }

        static get $inject() {
            return ["authService", "$transition$", "$state", "$mdToast", "$translate", "$timeout"];
        }

        $onInit() {
            this.promise = this.authService.getProfile(this.currentProfile)
                .then(data => {
                    this.profile = data;

                    this.profile.permissions = _.reduce(this.resources, (permissions, resource) => {
                        if (_.has(permissions, resource)) return permissions
                        else return _.assign(permissions, {[resource]: {name: resource, is_all: false, actions: {}}});
                    }, _.isEmpty(this.profile.permissions) ? {} : this.profile.permissions);
                });
        }


        goBack() {
            this.$state.go('app.parameters.profile-setup');
        }

        handleSave() {
            this.promise = this.authService.handleProfile(this.profile)
                .then(() => {
                    let simpleToast = this.toast.simple()
                        .textContent(this.examSetupEditSuccess)
                        .position("bottom left")
                        .hideDelay(1500);

                    this.$timeout(() => this.toast.show(simpleToast), 100);
                });
        }

        getResourceChoices(resource) {
            let resourceIndex = _.indexOf(this.resources, resource);
            let resourceItem = _.get(PROFILE_RESOURCES, resourceIndex);

            if (_.isString(resourceItem)) return RESOURCE_CHOICES;
            else return _.concat(RESOURCE_CHOICES, _.get(resourceItem, 'extra-actions', []));
        }

    }

    module.exports = ProfileSetupFormCtrl;

})();