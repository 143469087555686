/**
 * Created by BETALOS on 08/07/2016.
 */
(function () {

    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const ENTRY_ROOM_UPDATE_DIALOG = require('../dialogs/entry-room-update-dialog');
    const BIOLOGY_RESULT_DIALOG = require('prescription/dialogs/biology-result-dialog');

    class PracticeMenuCtrl {
        constructor(
            $state, mnWebSocket, frontDeskService, $mdDialog, $attrs, $element, $scope, $compile, interfacingService,
            dcmService, practiceService, visitService, notificationService, patientService, system, moment, hurService, configService
        ) {
            this.$scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dialog = $mdDialog;
            this.$element = $element;
            this.$compile = $compile;

            this.ws = mnWebSocket;
            this.visitService = visitService;
            this.patientService = patientService;
            this.practiceService = practiceService;
            this.frontDeskService = frontDeskService;
            this.notificationService = notificationService;
            this.configService = configService;

            this.createDcmOrder = dcmService.createDcmOrder;
            this.createHurOrder = hurService.createHurOrder;
            this.createOrder = interfacingService.createOrder;
            this.decideMethod = frontDeskService.decideMethod;

            this.dayFilter = true;
            this.dateFormat = system['date_format'].js;

            $attrs.$observe('html', () => this.compile($attrs));
        }

        static get $inject() {
            return [
                "$state", "mnWebSocket", "frontDeskService", "$mdDialog", "$attrs", "$element",
                "$scope", "$compile", "interfacingService", "dcmService", "practiceService", "visitService",
                "notificationService", "patientService", "system", "moment", "hurService", "configService"
            ];
        }

        $onInit() {
            this.payVisit = this.callbacks.payVisit;
            this.prePayVisit = this.callbacks.prePayVisit;
            this.editEntry = this.callbacks.editEntry;
            this.patientOut = this.callbacks.patientOut;
            this.patientFile = this.callbacks.patientFile;
            this.generateVisit = this.callbacks.generateVisit;
            this.payTreatmentPlans = this.callbacks.payTreatmentPlans;

            this.isDental = this.configService.isDental();

            this.visitSubLinksSubscription = this.visitService.visitSubLinks.subscribe(
                data => this.handleSubLinks(data)
            );

            this.roomSubscription = this.frontDeskService.roomSubject
                .subscribe(data => this.rooms = _.filter(data, {type: 'WR'}));
        }

        $onDestroy() {
            if (this.roomSubscription) this.roomSubscription.unsubscribe();
            if (this.visitSubLinksSubscription) this.visitSubLinksSubscription.unsubscribe();
        }

        compile(attrs) {
            let tpl = require(
                `frontdesk/views/${attrs['compId']}-${attrs.html.toLowerCase()}-menu.tpl.html`
            );

            if (this.$element.is('[icon-button]')) {
                let $tpl = $(tpl);
                $('md-button:eq(0)', $tpl).addClass('md-icon-button');

                tpl = $tpl.prop('outerHTML');
            }

            let compiledDirective = this.$compile(tpl);
            let directiveElement = compiledDirective(this.$scope);

            this.$element.html(directiveElement);
        }

        handleSubLinks(data) {
            this.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data['favorite']]);
        }

        showLastVisit(item) {
            return this.$state.go(this.visitLinkConfig.link, {
                visitId: item.patient['last_visit_id'], pId: item.patient.id
            });
        }

        addNewRdv(item, ev) {
            return this.frontDeskService.addNewAppointment(item.patient, ev);
        }

        cancelEntry(item) {
            return this.frontDeskService.cancelEntry(item)
                .then(data => {
                    this.practiceService.entryCanceled(data.entry);
                    this.ws.pub("frontdesk.Practice.entry_canceled", data.entry);

                    if (item['has_appointment']) this.ws.pub("frontdesk.Calendar.notify", data.appointment, false);
                });
        }

        patientAlerts(item, ev) {
            this.patientService.patientAlerts(item.patient, ev);
        }

        addNewAlert(item, ev) {
            this.notificationService.alertDialog(item.patient, item.stat, null, ev);
        }

        addNewNotification(item, ev) {
            this.notificationService.notificationDialog(null, item.patient, ev);
        }

        addBiologyResult(item, ev) {
            this.dialog.show(_.assign({}, BIOLOGY_RESULT_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient: item.patient.id
                }
            }));
        }

        openMenu($mdMenu, $event) {
            $event.stopPropagation();
            $mdMenu.open($event);
        }

        updateRoom(ev) {
            this.dialog.show(_.assign({}, ENTRY_ROOM_UPDATE_DIALOG, {
                targetEvent: ev,
                locals: {
                    entry: _.cloneDeep(this.item)
                }
            }));
        }

        patientResume(item, ev) {
            this.frontDeskService.entryResume({
                'entry': item,
                'patient-id': _.get(item, 'patient.id')
            }, ev);
        }

        startNewEntry(stat) {
            this.frontDeskService.checkPatientEntry(this.item.patient.id)
                .then(() => this.newEntrySuccess(stat));
        }

        newEntrySuccess(stat) {
            this.frontDeskService.reopenEntry(this.item, stat)
                .then(data => {
                    this.practiceService.entryAdded(data);
                    this.ws.pub("frontdesk.Practice.entry_added", data);
                });
        }

        isEntryToday() {
            if (!this.dayFilter) return true;
            else return this.moment(this.item.entry_time).format(this.dateFormat) === this.moment().format(this.dateFormat);
        }

        // rdv related methods
        isToday() {
            return this.item.date === this.moment().format(this.dateFormat);
        }

        createEntry(event, stat, room) {
            this.frontDeskService.createEntryFromCalendar(event, stat, room)
                .then(data => {
                    this.notifyCalendar(data);
                    this.practiceService.entryAdded(data.entry);
                    this.ws.pub("frontdesk.Practice.entry_added", data.entry);
                });
        }

        editEvent(appointment, ev) {
            this.frontDeskService.editAppointment(appointment, ev);
        }

        toWaitingList(event) {
            const data = {date: null, start_time: null, end_time: null, ignore: true, is_waiting_room: true};
            this.frontDeskService.partialUpdateAppointment(event.id, data)
                .then(data => this.notifyCalendar(data));
        }

        deleteEvent(event) {
            this.frontDeskService.removeAppointment(event.id)
                .then(data => this.notifyCalendar(data));
        }

        notifyCalendar(data) {
            this.ws.pub("frontdesk.Calendar.notify", data, false);
        }
    }

    module.exports = {
        bindings: {
            item: "=",
            dayFilter: "<",
            callbacks: "<",
        },
        controllerAs: 'vm',
        controller: PracticeMenuCtrl,
    };

})();