/**
 * Created by amine on 04/04/2018.
 */
(function () {
    'use strict';

    module.exports = maintenanceService;

    maintenanceService.$inject = ["mnWebSocket"];

    function maintenanceService(mnWebSocket) {
        var self = this;

        self.fusePatients = fusePatients


        function fusePatients(primary, secondary) {
            primary = _.get(primary, "id")
            secondary = _.get(secondary, "id")

            return mnWebSocket.call("patient.PatientMinimal.fuse_patients", {primary: primary, secondary: secondary})
        }
    }

})();
