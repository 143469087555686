/**
 * Created by BETALOS on 24/09/2016.
 */
(function () {
    'use strict';

    class SearchDialogCtrl {
        constructor($mdDialog, patientService, frontDeskService, system, $translate, mnWebSocket, $q, $timeout, moment, externalConnectionService) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.mnWebSocket = mnWebSocket;
            this.patientService = patientService;
            this.frontDeskService = frontDeskService;
            this.externalConnectionService = externalConnectionService;

            this.searchNotStarted = true;
            this.initialAppointments = null;
            this.dateFormat = system['date_format'].js;

            this.query = {page: 1, limit: 5};
            this.search = {
                deleted: false,
                start: moment().format(this.dateFormat),
                end: moment().format(this.dateFormat),
            };

            this.selectedAppointments = [];

            this.paginationLabel = {
                of: $translate['instant']('of'),
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
            }

            const nEqual = {"$ne": true};
            this.filter = {is_deleted: nEqual, is_archived: nEqual};
            this.printSearch = () => this.printSearchCall();
        }

        static get $inject() {
            return [
                "$mdDialog", "patientService", "frontDeskService",
                "system", "$translate", "mnWebSocket", "$q", "$timeout",
                "moment", "externalConnectionService"
            ];
        }

        $onInit() {
            this.removePatient = () => this.removePatientFunc();
            this.patientCallback = (patient) => this.patientCallbackFunc(patient);

            this.onPaginate = (page, limit) => this.onPaginateFunc(page, limit);
            this.edit = (appointment, ev) => this.frontDeskService.editAppointment(appointment, ev);
        }

        cancel() {
            this.dialog.cancel();
        }

        startSearch() {
            const query = this.getSearchQuery();

            this.promise = this.frontDeskService.searchAppointment(query)
                .then((data) => {
                    this.searchNotStarted = false;
                    this.initialAppointments = data;

                    this.size = this.initialAppointments.length;
                    this.appointments = this.filterFunc(this.query);
                    this.selectAppointments();
                });
        }

        resetSearch() {
            this.searchNotStarted = true;
            this.initialAppointments = null;
        }

        patientCallbackFunc(patient) {
            this.patientService.getMinimalPatient(patient.id)
                .then(data => this.search.patient = data);
        }

        removePatientFunc() {
            this.search.patient = null;
        }

        getSearchQuery() {
            let search = _.clone(this.search);

            if (!_.isUndefined(search.reason)) search.reason = _.map(search.reason, 'id');
            if (!_.isUndefined(search.agenda)) search.agenda = _.map(search.agenda, 'id');
            if (!_.isUndefined(search.physician)) search.physician = _.map(search.physician, 'id');
            if (!_.isUndefined(search.patient)) search.patient = _.get(search.patient, 'id');

            return search;
        }

        printSearchCall() {
            const deferred = this.$q.defer();

            this.$timeout(() => deferred.resolve({"ids": this.selectedAppointments}), 300);

            return deferred.promise;
        }

        sendSMS($event) {
            this.externalConnectionService
                .showSMSDialog($event, "send_appointment_sms", {"ids": this.selectedAppointments});
        }

        clearInterval() {
            this.search.end = null;
            this.search.start = null;
        }

        filterFunc(query) {
            let offset = (query["page"] - 1) * query["limit"];

            let start = offset > this.initialAppointments.length ? 0 : offset;
            let end = start + query["limit"];

            return _.slice(this.initialAppointments, start, end);
        }

        onPaginateFunc(page, limit) {
            this.appointments = this.filterFunc({page: page, limit: limit});
        }

        remove(appointment) {
            this.promise = this.frontDeskService.removeAppointment(appointment.id)
                .then(data => {
                    _.remove(this.initialAppointments, {id: appointment.id});

                    this.size = this.initialAppointments.length;
                    this.appointments = this.filterFunc(this.query);
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", data, false);
                });
        }

        selectAppointments(select = true) {
            if (select) this.selectedAppointments = _.map(this.initialAppointments, 'id');
            else this.selectedAppointments = [];
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        clickOutsideToClose: false,
        controller: SearchDialogCtrl,
        template: require("../views/appointment-search.tpl.html"),
    };

})();
